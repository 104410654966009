import React from 'react';
import {Route, Routes} from "react-router-dom";
import PrivateRoutes from "./components/private/PrivateRoutes";
import Login from "./components/public/login";
import Dashboard from "./components/private/Dashboard";
import "./index.css"
import {createTheme, ThemeProvider} from "@mui/material/styles";

const mdTheme = createTheme({
    typography: {
        fontSize: 12,
        fontFamily: "Baloo-2-500",
    },
    palette: {
        primary: {
            main: '#F47834',
            contrastText: '#FFF'
        },
        text: {
            primary: '#F3c4559',
        },
        disabled: {
            main: 'rgba(0, 0, 0, 0.4)',
            contrastText: '#FFF',
        }
    },
    components: {
        MuiTable: {
            styleOverrides: {
                root: {
                    '& .MuiTableRow-root': {
                        '&:nth-of-type(even)': {
                            backgroundColor: '#FFF',
                        },
                    },

                    '& .MuiTableCell-root': {
                        border: '1px solid rgba(0,0,0,0.2)',
                        padding: '2px 4px'
                    },

                    '& .MuiTableCell-head': {
                        backgroundColor: '#e3e4e9',
                        fontFamily: 'Baloo-2-800'
                    }

                },
            }
        },
        MuiListSubheader: {
            styleOverrides: {
                root: {paddingLeft: 15}
            }
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {minWidth: 25}
            }
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {padding: 0}
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {position: "relative"}
            }
        }
    }
});

function App() {
    return (
        <ThemeProvider theme={mdTheme}>
            <Routes>
                <Route element={<PrivateRoutes/>}>
                    <Route path="*" element={<Dashboard />}/>
                </Route>
                <Route path="/login" element={<Login/>}/>
            </Routes>
        </ThemeProvider>
    );
}

export default App;
