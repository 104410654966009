import {Alert, AlertColor, Snackbar} from "@mui/material";
import {Context, createContext, useEffect, useState} from "react";
import {AuthGetOptions, PostOptions, ResponseHandler} from "Requests/requests";
import {useNavigate} from "react-router-dom";
import useAuth from "hooks/useAuth";
import {TFetchContext, TResponse} from "../types";
import {rTestToken} from "../Requests/backend_routes";


interface Props {
    children?: any,
    single?: boolean
}

type GenericProviderProps = {
    getApi: URL
    defaultT: object | object[] | undefined
}

const normalFetch:
    (url: URL, options: RequestInit) => Promise<Response>
    =
    async (url: URL, options: RequestInit) => {
        return await fetch(url, options);
    };

export async function getData<T>(
    api: URL,
    skip?: number,
    limit?: number,
    setter?: any,
    rejecter?: any
): Promise<void> {
    if (skip !== undefined && limit !== undefined) {
        api.searchParams.get('skip') && api.searchParams.set('skip', skip.toString())
        api.searchParams.get('limit') && api.searchParams.set('limit', limit.toString())

        !api.searchParams.get('limit') && api.searchParams.append('skip', skip.toString())
        !api.searchParams.get('limit') && api.searchParams.append('limit', limit.toString())
    }
    const res: Awaited<Response> = await normalFetch(api, AuthGetOptions())
    await ResponseHandler<T>(res, rejecter, setter)
}

export default function createGenericContext<T>({getApi, defaultT}: GenericProviderProps) {

    const MyContext: Context<TFetchContext<T>> = createContext<TFetchContext<T>>(defaultT as TFetchContext<T>)

    function FetchContextProvider({children}: Props): JSX.Element {

        const {setUser} = useAuth();
        const [snackOpen, setSnackOpen] = useState<boolean>(false);
        const [responseText, setResponseText] = useState<string>("");
        const [responseType, setResponseType] = useState<AlertColor>("info");
        const [reFetch, setReFetch] = useState<boolean>(false);
        const [response, setResponse] = useState<T | undefined>(undefined);
        const [loading, setIsLoading] = useState<boolean>(true);
        const [err, setErr] = useState<boolean>(false)
        const navigate = useNavigate();

        function onReject(err: TResponse) {
            setSnackOpen(true);
            setResponseText(err.detail as string);
            setResponseType("error");
        }

        async function validateToken(): Promise<void> {
            const res: Awaited<Response> = await normalFetch(rTestToken, PostOptions())
            const onTokenReject: () => void = () => {
                delete localStorage.token;
                setUser(false);
                navigate("/");
            }
            await ResponseHandler(res, onTokenReject)
        }

        function useGetData(newForm?: boolean, skip?: number, limit?: number) {
            useEffect(() => {
                if (newForm) {
                    setIsLoading(false)
                    return
                }
                const fetchData: () => void = async () => {
                    const [token, data] = await Promise.allSettled([
                        validateToken(),
                        getData(getApi, skip, limit, setResponse, onReject)
                    ]);
                    if (token.status === "rejected" || data.status === "rejected") {
                        setIsLoading(false);
                        setResponseType("error");
                        setResponseText("Problème réseaux");
                        setSnackOpen(true);
                        setErr(true);
                    }
                }
                fetchData();
                setIsLoading(false)
            }, [reFetch])

            return {response, loading, err}

        }

        const fetchForCUD: TFetchContext<T>['fetchForCUD']
            =
            async (url: URL, options: RequestInit, successMsg: string) => {
                const [resAll] = await Promise.allSettled([
                    async () => {
                        const response: Awaited<Response> = await normalFetch(url, options)
                        const result: Promise<any> = await ResponseHandler(response, onReject);
                        if (response.status === 200) {
                            setResponseText(successMsg)
                            setResponseType('success')
                            setSnackOpen(true)
                            setIsLoading(false)
                            setResponse(undefined)
                            setReFetch(!reFetch)
                        }
                        return [await result, response.status];
                    }
                ])
                if (resAll.status === "rejected") {
                    setIsLoading(false);
                    setResponseType("error");
                    setResponseText("Problème réseaux");
                    setSnackOpen(true);
                    setErr(true);
                    return
                } else {
                    return await resAll.value()
                }

            }
        return (
            <>
                <Snackbar
                    anchorOrigin={{vertical: "top", horizontal: "right"}}
                    open={snackOpen}
                    autoHideDuration={6000}
                    onClose={(e) => setSnackOpen(false)}
                >
                    <Alert
                        onClose={(e) => setSnackOpen(false)}
                        severity={responseType}
                        sx={{width: "100%"}}
                    >
                        {responseText}
                    </Alert>
                </Snackbar>
                <MyContext.Provider
                    value={{
                        useGetData,
                        fetchForCUD,
                        reFetch,
                        loading,
                        setReFetch,
                        setIsLoading,
                        setResponse
                    }}
                >
                    {children}
                </MyContext.Provider>
            </>
        );
    }

    return {MyContext, FetchContextProvider}
}

