import {ListRecords} from "components/special/list_view";
import {Cancel, CheckCircle, Image, Launch} from "@mui/icons-material";
import {IMAGES_URL} from "Requests/requests";
import {Column} from "@material-table/core";
import {FastBid} from "../../../client";
import {groupRender, TableName} from "../../../tools/tools";
import {rBids} from "../../../Requests/backend_routes";
import createGenericContext from "../../../context/FetchContext";
import {Link, useNavigate} from "react-router-dom";

const DELETE_MSG = "Announce supprimée";

type TBids = {
    defaultApi?: URL
    title?: string
    from?: "domain" | "store" | "category"
}

export const Bids = (props: TBids) => {
    const {MyContext, FetchContextProvider} = createGenericContext<FastBid>({getApi: props.defaultApi ?? rBids, defaultT: []})

    const navigate = useNavigate()

    const columns: Column<FastBid>[] =
        [
            {title: "ID", field: "id", editable: "never", hidden: true, width: '1%'},
            {
                field: "active",
                title: "Actif",
                render: (rowData) => rowData.active ? <CheckCircle style={{color: "green"}}/> :
                    <Cancel style={{color: "red"}}/>,
                groupRender: (groupKey) => groupRender(groupKey, <CheckCircle style={{color: "green"}}/>, <Cancel
                    style={{color: "red"}}/>),
                width: '2%',
                filtering: true,
                type: "boolean"
            },
            {
                field: "picture",
                title: "Image",
                render: (rowData) => {
                    return (
                        <>
                            {rowData.picture && (
                                <img
                                    src={`${IMAGES_URL}/${rowData.picture}`}
                                    style={{width: 120, height: 80, objectFit: 'fill'}}
                                />
                            )}

                            {!rowData.picture && (
                                <Image
                                    sx={{width: 120, height: 120, objectFit: 'fill'}}
                                    htmlColor="grey"
                                />
                            )}
                        </>
                    );
                },
                editable: "never",
                filtering: false,
                grouping: false
            },
            {
                title: "Nom",
                field: "name",
                editable: "never",
                render: (rowData) => <TableName rowData={rowData} onClick={() => navigate(`/bids/${rowData.id}`)}/>
            },
            {
                title: "Catégorie",
                field: "category.full_name",
                render: (rowData) => (
                    <>
                        {rowData.category.full_name}
                        <Link to={`/categories/${rowData.category.id}`}>
                            <Launch/>
                        </Link>
                    </>
                ),
                editable: "never"
            },
            {
                title: "Boutique", field: "store.name", render: (rowData) => (
                    <>
                        {rowData.store.name}
                        <Link to={`/stores/${rowData.store.id}`}>
                            <Launch/>
                        </Link>
                    </>
                ),
                editable: "never"
            },
            {
                title: "Domain", field: "store.domain.full_name", render: (rowData) => (
                    <>
                        {rowData.store.domain.full_name}
                        <Link to={`/domains/${rowData.store.domain.id}`}>
                            <Launch/>
                        </Link>
                    </>
                ),
                editable: "never"
            },
            {
                title: 'Créée le',
                field: 'created_date',
                type: 'datetime',
                defaultSort: 'desc',
                filtering: false,
                width: '10%',
                editable: "never"
            },
        ]

    if (props.from) {
        props.from === "domain" ? columns.splice(6) : props.from === "store" ? columns.splice(5) : columns.splice(4)
    }

    return (
        <>
            <FetchContextProvider>
                <ListRecords
                    title={props.title ?? "Tous les announces"}
                    ctx={MyContext}
                    columns={columns}
                    options={{
                        pageSizeOptions: [100, 200, 500],
                        pageSize: 100,
                        grouping: true,
                        filtering: true,
                        showFirstLastPageButtons: true,
                    }}
                    skip={0}
                    limit={1000}
                    creatable={props.from === "domain" ? undefined :{type: 'newWindow', url: '/bids/new'}}
                    deletable={{type: 'inline', url: rBids.pathname, msg: DELETE_MSG}}
                />
            </FetchContextProvider>
        </>
    );
};
