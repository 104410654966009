import {useParams} from "react-router-dom";
import createGenericContext from "../../../context/FetchContext";
import {AdminUserOut} from "../../../client";
import {rUsers} from "../../../Requests/backend_routes";
import {BASE_URL} from "../../../Requests/requests";
import React, {SyntheticEvent} from "react";
import {TFormField} from "../../../types";
import {AdminFormSample} from "../../form/AdminFormSample";
import {FormContextProvider} from "../../../context/FormContext";
import {useNavigate} from "react-router";

export function UserController(): JSX.Element {

    const {id} = useParams();
    const navigate = useNavigate()

    const {
        MyContext,
        FetchContextProvider
    } = createGenericContext<AdminUserOut>(
        {
            getApi: new URL(`${rUsers.pathname}/${id}`, BASE_URL),
            defaultT: {}
        }
    )

    const fields: TFormField<AdminUserOut>[] = [
        {
            field: "full_name",
            label: 'Nom Complet',
            placeHolder: "Nom complet",
            create: "required",
            update: "optional",
            position: 'Left',
            order: 1,
            type: "text"
        },
        {
            field: "email",
            label: "Email",
            placeHolder: "Email de l'utilisateur",
            create: "required",
            update: "readonly",
            position: 'Left',
            order: 2,
            type: "text"
        },
        {
            field: "password",
            label: "Mot de passe",
            placeHolder: "Mot de passe de l'utilisateur",
            create: "required",
            update: "optional",
            position: 'Left',
            order: 3,
            excludeIfEmpty: true,
            type: "password"
        },
        {
            field: "phone",
            label: "Téléphone",
            placeHolder: "Téléphone de l'utilisateur",
            create: "optional",
            update: "optional",
            position: 'Left',
            order: 4,
            type: "text"
        },
        {
            field: "is_active",
            label: "Actif",
            placeHolder: "Actif",
            create: "optional",
            update: "optional",
            position: 'Header',
            order: 1,
            type: "boolean"
        },
        {
            field: "is_man",
            label: "Homme?",
            create: "optional",
            update: "optional",
            position: 'Left',
            order: 5,
            type: "boolean"
        },
        {
            field: "picture",
            label: "Image",
            create: "optional",
            update: "optional",
            position: 'Right',
            order: 1,
            type: "picture"
        }
    ]

    async function afterDone(res: any, e: SyntheticEvent<HTMLFormElement, SubmitEvent>) {
        const uid = parseInt(res)
        if(!isNaN(uid) && id === "new") navigate(`/users/${uid}`)
    }
    return (
        <>
            <FetchContextProvider>
                <FormContextProvider startMode={id === "new" ? 'create' : undefined}>
                    <AdminFormSample<AdminUserOut>
                        id={id as string}
                        baseApi={rUsers}
                        renderFields={fields}
                        ctx={MyContext}
                        successCreateMsg="Utilisateur ajouté avec succès"
                        successUpdateMsg="Utilisateur modifié avec succès"
                        callBack={afterDone}
                    />
                </FormContextProvider>
            </FetchContextProvider>
        </>
    )
}
