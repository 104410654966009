import {useParams} from "react-router-dom";
import createGenericContext, {getData} from "../../../context/FetchContext";
import {BidCategory, ModelCommon, SingleBidForEdit} from "../../../client";
import {rBids, rColors, rPriceTypes, rSaleTypes, rStockStates} from "../../../Requests/backend_routes";
import {BASE_URL} from "../../../Requests/requests";
import React, {SyntheticEvent, useEffect, useState} from "react";
import {TFormField} from "../../../types";
import {AdminFormSample} from "../../form/AdminFormSample";
import {FormContextProvider} from "../../../context/FormContext";
import {useNavigate} from "react-router";
import {formStore} from "../../../formStore";
import {BidCategorySelector} from "../../form/Widgets/BidCategorySelector";
import {Paper} from "@mui/material";

let bidReducer = formStore<SingleBidForEdit>()

export function BidController(): JSX.Element {

    const [bid, setBid] = useState<SingleBidForEdit | undefined>(undefined)
    const [state, setState] = useState<{ category?: BidCategory, store?: ModelCommon } | undefined>(
        {category: bid?.category, store: bid?.store}
    )

    const {id} = useParams();
    const navigate = useNavigate()
    const {
        MyContext,
        FetchContextProvider
    } = createGenericContext<SingleBidForEdit>(
        {
            getApi: new URL(`${rBids.pathname}/${id}`, BASE_URL),
            defaultT: {}
        }
    )

    const fields: TFormField<SingleBidForEdit>[] = [
        {
            field: 'active',
            label: 'Actif',
            position: 'Header',
            type: 'boolean',
            order: 1,
            create: 'optional',
            update: 'optional'
        },
        {
            field: "name",
            label: 'Nom',
            placeHolder: "Nom",
            create: "hidden",
            update: "readonly",
            position: 'Left',
            order: 1,
            type: "text"
        },
        {
            field: "description",
            label: 'Description',
            placeHolder: "Description de l'annonce",
            create: "optional",
            update: "optional",
            position: 'Left',
            order: 3,
            type: "long_text"
        },
        {
            field: "picture",
            create: "required",
            update: "required",
            position: 'Right',
            order: 1,
            type: "picture"
        },
        {
            field: "price",
            label: "Prix",
            create: "required",
            update: "optional",
            position: 'Right',
            order: 2,
            type: "float",
            step: "0.01"
        },
        {
            field: "price_type",
            label: "Type de prix",
            create: "required",
            update: "optional",
            position: 'Right',
            order: 3,
            type: "many2one",
            localObject: "bid_price_type",
            externalLabelKey: "name",
            localValue: "name",
            relationalAPI: rPriceTypes
        },
        {
            field: "stock_state_id",
            label: "Etat de stock",
            create: "optional",
            update: "optional",
            position: 'Right',
            order: 4,
            type: "many2one",
            localObject: "stock_state",
            externalLabelKey: "name",
            localValue: "name",
            relationalAPI: rStockStates
        },
        {
            field: "sale_types",
            label: "Types de vente",
            create: "optional",
            update: "optional",
            position: 'Right',
            order: 5,
            type: "many2many",
            relationalAPI: rSaleTypes,
        },
        {
            field: "pictures",
            create: "optional",
            update: "optional",
            position: 'Right',
            order: 7,
            type: "pictures",
            maxItems: 7
        }
    ]

    if (state?.category) {
        fields.push(
            {
                field: "attribute_values",
                label: 'Attributes',
                create: "required",
                update: "required",
                position: 'Left',
                order: 2,
                type: "attributes",
                catId: state?.category.id
            })

        if (state.category.bids_has_colors) {
            fields.push(
                {
                    field: "colors",
                    label: "Couleurs",
                    create: "optional",
                    update: "optional",
                    position: 'Right',
                    order: 6,
                    type: "many2many_colors",
                    relationalAPI: rColors,
                })
        }
    }

    async function afterDone(res: any, e: SyntheticEvent<HTMLFormElement, SubmitEvent>) {

        const uid = parseInt(res)
        if (!isNaN(uid) && id === "new") {
            navigate(`/bids/${uid}`)
        }
    }

    useEffect(() => {
        if (id !== "new") {
            (async () => await getData<SingleBidForEdit>(new URL(`${rBids.pathname}/${id}`, BASE_URL), 0, 1000, setBid))()
        }
    }, [id])

    useEffect(() => {
        setState({...state, category: bid?.category, store: bid?.store})
    }, [bid])

    return (
        <>
            <FetchContextProvider>
                <FormContextProvider startMode={id === "new" ? "create" : undefined}>
                    <Paper elevation={4} style={{padding: "5px 5px", marginBottom: "10px"}}>
                        <BidCategorySelector onChangeCategory={setBid} bid={bid}/>
                    </Paper>
                    {state?.category && (
                        <AdminFormSample<SingleBidForEdit>
                            id={id as string}
                            ctx={MyContext}
                            baseApi={rBids}
                            renderFields={fields}
                            successCreateMsg="Annonce ajoutée avec succès"
                            successUpdateMsg="Annonce modifiée avec succès"
                            callBack={afterDone}
                            reducer={bidReducer}
                            fixedFields={[
                                {
                                    field: 'category_id', value: state.category?.id,
                                },
                                {
                                    field: 'store_id', value: state.store?.id
                                }
                            ]}
                        />
                    )}
                </FormContextProvider>
            </FetchContextProvider>
        </>
    )
}
