import {ListRecords} from "components/special/list_view";
import createGenericContext from "context/FetchContext";
import {groupRender, nested_name_render} from "tools/tools";
import {Category} from "../../../client";
import {rCategories} from "../../../Requests/backend_routes";
import {TFormField} from "../../../types";
import {Column} from "@material-table/core";
import {useNavigate} from "react-router";
import {Cancel, CheckCircle} from "@mui/icons-material";
import React from "react";

const DELETE_MSG = "Catégorie supprimée";
export function categoryFormFields(id: string): TFormField<Category>[] {

    return [
    {
        field: "full_name",
        label: 'Nom Complet',
        placeHolder: "Nom complet",
        create: "hidden",
        update: "readonly",
        position: 'Left',
        order: 1,
        type: "text"
    },
    {
        field: "name",
        label: 'Nom',
        placeHolder: "Nom",
        create: "required",
        update: "optional",
        position: 'Left',
        order: 2,
        type: "text"
    },
    {
        field: "parent_id",
        label: 'Category parente',
        placeHolder: "Category parente",
        create: "optional",
        update: "optional",
        position: 'Left',
        order: 3,
        type: "many2one",
        relationalAPI: rCategories,
        localObject: "parent",
        localValue: "name",
        externalLabelKey: "full_name",
        filterFunc: (item: Category) => item.id !== parseInt(id)
    },
    {
        field: "sequence",
        label: 'Ordre',
        placeHolder: "Ordre",
        create: "optional",
        update: "optional",
        position: 'Left',
        order: 4,
        type: "number"
    },
    {
        field: "picture",
        create: "optional",
        update: "optional",
        position: 'Right',
        order: 1,
        type: "picture"
    },
    {
        field: "bids_has_colors",
        label: "Annonces avec coleurs",
        create: "optional",
        update: "optional",
        position: 'Header',
        order: 1,
        type: "boolean"
    },
    {
        field: "active",
        label: "Actif?",
        create: "optional",
        update: "optional",
        position: 'Header',
        order: 2,
        type: "boolean"
    }
]
}

const {MyContext, FetchContextProvider} = createGenericContext<Category>({getApi: rCategories, defaultT: []})

export const Categories = () => {
    const navigate = useNavigate()
    const columns: Column<Category>[] = [
        {title: "ID", field: "id", hidden: true, width: '1%', editable: "never"},
        {title: 'Ordre', field: 'sequence', defaultSort: 'asc', width: '1%'},
        {
            title: "Nom",
            field: "name",
            width: '90%',
            render: (rowData: Category) => nested_name_render(rowData)
        },
        {
            field: "active",
            title: "Actif?",
            type: "boolean",
            groupRender: (groupKey) => groupRender(groupKey, <CheckCircle style={{color: "green"}}/>, <Cancel
                style={{color: "red"}}/>),
            render: (data) => data.active ? <CheckCircle htmlColor='green' /> : <Cancel htmlColor='red' />,
            width: '5%'
        },
        {
            field: "bids_has_colors",
            title: "Coleurs?",
            type: "boolean",
            groupRender: (groupKey) => groupRender(groupKey, <CheckCircle style={{color: "green"}}/>, <Cancel
                style={{color: "red"}}/>),
            render: (data) => data.bids_has_colors ? <CheckCircle htmlColor='green' /> : <Cancel htmlColor='red' />,
            width: '5%'
        },
        {
            title: "Créé le",
            field: "created_date",
            type: "date",
            grouping: false,
            filtering: false,
            hidden: true,
            width: '15%',
            editable: "never"
        },
        {
            title: "Dernière modif",
            field: "updated_date",
            type: "date",
            grouping: false,
            filtering: false,
            hidden: true,
            width: '15%',
            editable: "never"
        }
    ];

    return (
        <FetchContextProvider>
            <ListRecords<Category>
                title="Tous les catégories"
                ctx={MyContext}
                columns={columns}
                options={{
                    pageSizeOptions: [100, 200, 500],
                    pageSize: 100,
                    actionsColumnIndex: -1
                }}
                parentChildData={
                    (row, rows) => rows.find((a) => a.id === row.parent_id)
                }
                creatable={
                    {
                        type: 'popup',
                        formProps: {
                            baseApi: rCategories,
                            renderFields: categoryFormFields("new"),
                            ctx: MyContext,
                            successCreateMsg: "Category ajoutée avec succès",
                            successUpdateMsg: "Category modifiée avec succès",
                        }
                    }}
                deletable={{type: 'inline', url: rCategories.pathname, msg: DELETE_MSG}}
                onRowClick={(event, rowData) => navigate(`/categories/${rowData?.id}`)}
                updatable={{type: 'inline', url: 'categories', msg: 'Catégorie modifée avec succès'}}
            />
        </FetchContextProvider>
    );
};
