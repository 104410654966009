import {ListRecords} from "components/special/list_view"
import createGenericContext from "context/FetchContext";
import {FormControl, TextField} from "@mui/material";
import {Square} from "@mui/icons-material";
import {Column} from "@material-table/core";
import {MembershipPlan} from "../../../client";
import {rMembershipPlan} from "../../../Requests/backend_routes";

const CREATE_MSG = "Plan ajouté avec succès"
const UPDATE_MSG = "Plan modifié avec succès"
const DELETE_MSG = "Plan supprimé avec succès"

const {MyContext, FetchContextProvider} = createGenericContext<MembershipPlan>({getApi: rMembershipPlan, defaultT: []})

export const MembershipPlans = () => {

    const columns: Column<MembershipPlan>[] = [
        {title: "ID", field: "id", editable: 'never', hidden: true, width: '1%'},
        {title: 'Niveau', field: 'level', defaultSort: 'asc', width: "1%"},
        {title: "Nom", field: "name", width: "20%"},
        {
            title: "Coleur",
            field: "color",
            editComponent: (props) =>
                <FormControl fullWidth>
                    <TextField
                        type="color"
                        name="color"
                        value={props.value ? props.value : ''}
                        onChange={e => props.onChange(e.target.value)}
                    />
                </FormControl>,
            render: (rowData) => <Square sx={{...(rowData.color && {color: rowData.color})}}/>,
            width: "1%"
        },
        {title: 'Ann.Limit', field: 'bids_limit'},
        {title: 'Nombre de mois', field: 'month_step'},
        {title: 'Cycle de refresh (jours)', field: 'refresh_days'},
        {title: 'Max refresh', field: 'max_refresh'},
        {title: 'Prix', field: 'price_per_step'}
    ]


    return (
        <FetchContextProvider>
            <ListRecords<MembershipPlan>
                title="Plans d'abonnement"
                ctx={MyContext}
                columns={columns}
                creatable={{type: 'inline', url: `${rMembershipPlan.pathname}/new`, msg: CREATE_MSG}}
                updatable={{type: 'inline', url: rMembershipPlan.pathname, msg: UPDATE_MSG}}
                deletable={{type: 'inline', url: rMembershipPlan.pathname, msg: DELETE_MSG}}
            />
        </FetchContextProvider>
    );
}

