import React, {PropsWithChildren, useEffect, useState} from "react";
import {AttributeFull, SingleBidForEdit} from "../../../client";
import {useFormContext} from "../../../context/FormContext";
import {CircularProgress} from "@mui/material";
import {getData} from "../../../context/FetchContext";
import {BASE_URL} from "../../../Requests/requests";
import {AttributeWidget} from "./attribute/attribute_widget";

var groupBy = function (xs: any[], key: string) {
    return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};

type TBidAttributes = PropsWithChildren<{
    values?: SingleBidForEdit["attribute_values"]
    catId: number
}>

export function BidAttributes(props: TBidAttributes): JSX.Element {

    if (!props.catId) {
        throw Error('Unexpected Error')
    }

    const {mode} = useFormContext()

    if (mode === "update" && !props.values) {
        throw Error('Unexpected Error')
    }

    const [loading, setLoading] = useState<boolean>(true)
    const [attributes, setAttributes] = useState<AttributeFull[] | undefined>()

    useEffect(
        () => {
            const catAttributes: URL = new URL(`categories/${props.catId}/attributes`, BASE_URL)
            if (!attributes) (async () => await getData(catAttributes, 0, 1000, setAttributes))()
            setLoading(false)
        },
        []
    )

    if (loading || !attributes) return <CircularProgress/>

    const grouped: { [key: string]: AttributeFull[] } = groupBy(attributes, 'attribute_group_id')

    return (
        <>
            {
                (() => {
                    let idx: number = 0;
                    let items: JSX.Element[] = []
                    if (attributes?.length <= 0) return <></>

                    while (idx < attributes.length) {
                        const item: AttributeFull = attributes[idx]
                        if (item.attribute_group_id) {
                            idx += grouped[item.attribute_group_id].length
                            items.push(
                                <div className='customLegend' key={idx}>
                                    <h1><span> {item.group?.name} </span></h1>
                                    {
                                        grouped[item.attribute_group_id].map(
                                            (attr, index) =>
                                                <AttributeWidget
                                                    attribute={attr}
                                                    bidValues={props.values}
                                                    key={index + 100}
                                                />
                                        )
                                    }
                                </div>
                            )
                        } else {
                            idx += 1
                            items.push(
                                <AttributeWidget
                                    attribute={item}
                                    bidValues={props.values}
                                    key={idx}
                                />
                            )
                        }
                    }
            return items
            })()
            }
        </>
    )
}
