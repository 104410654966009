import {ListRecords} from "components/special/list_view";
import {groupRender, nested_name_render} from "tools/tools";
import {Domain} from "../../../client";
import {Column} from "@material-table/core";
import {rDomains} from "../../../Requests/backend_routes";
import createGenericContext from "../../../context/FetchContext";
import {useNavigate} from "react-router";
import {Cancel, CheckCircle} from "@mui/icons-material";
import React from "react";

const DELETE_MSG = "Domain supprimé";

const {MyContext, FetchContextProvider} = createGenericContext<Domain>({getApi: rDomains, defaultT: []})


export const Domains = () => {

    const navigate = useNavigate()
    const columns: Column<Domain>[] = [
        {title: "ID", field: "id", editable: "never", hidden: true, width: '1%'},
        {title: "Ordre", field: 'sequence', width: '1%'},
        {
            title: "Nom",
            field: "name",
            width: '70%',
            render: (rowData) => nested_name_render(rowData)
        },
        {
            title: "Type",
            field: "type.name",
            editable: "never",
            width: '25%'
        },
        {
            title: "Pays",
            field: "country.name",
            editable: "never",
            width: '5%'
        },
        {
            title: "Boutiques?",
            field: "stores_on",
            type: "boolean",
            groupRender: (groupKey) => groupRender(groupKey, <CheckCircle style={{color: "green"}}/>, <Cancel
                style={{color: "red"}}/>),
            render: (data) => data.stores_on ? <CheckCircle htmlColor='green' /> : <Cancel htmlColor='red' />,
            width: '5%'
        },
        {
            field: "active",
            title: "Actif?",
            groupRender: (groupKey) => groupRender(groupKey, <CheckCircle style={{color: "green"}}/>, <Cancel
                style={{color: "red"}}/>),
            render: (data) => data.active ? <CheckCircle htmlColor='green' /> : <Cancel htmlColor='red' />,
            type: "boolean",
            width: '5%'
        },
        {
            title: "Créé le",
            field: "created_date",
            type: "date",
            grouping: false,
            filtering: false,
            hidden: true,
            editable: "never",
            width: '15%'
        },
        {
            title: "Dernière modif",
            field: "updated_date",
            type: "date",
            grouping: false,
            filtering: false,
            hidden: true,
            editable: "never",
            width: '15%'
        },
    ];

    return (
        <FetchContextProvider>
            <ListRecords<Domain>
                title='Domains'
                ctx={MyContext}
                columns={columns}
                options={{
                    pageSizeOptions: [100, 200, 500],
                    pageSize: 100,
                    actionsColumnIndex: -1
                }}
                parentChildData={(row, rows) =>
                    rows.find((a) => a.id === row.parent_id)
                }
                onRowClick={(event, rowData) => navigate(`/domains/${rowData?.id}`)}
                creatable={{type: 'newWindow', url: '/domains/new'}}
                updatable={{type: 'inline', url: 'domains', msg: 'Domain modifé avec suucès'}}
                deletable={{type: 'inline', url: rDomains.pathname, msg: DELETE_MSG}}
            />
        </FetchContextProvider>
    );
};
