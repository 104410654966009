import {AttributeFull, BidAttributesValues} from "../../../../client";
import React, {SyntheticEvent, useEffect, useState} from "react";
import {getData} from "../../../../context/FetchContext";
import {BASE_URL} from "../../../../Requests/requests";
import {FieldRenderer} from "../../FieldRenderer";
import Typography from "@mui/material/Typography";
import {Autocomplete, Input, TextField} from "@mui/material";
import {useFormContext} from "../../../../context/FormContext";


type TOneMany = {
    attribute: AttributeFull
    value?: BidAttributesValues
}


export function OneMany(props: TOneMany): JSX.Element {

    const [list, setList] = useState<BidAttributesValues[]>([]);
    const [open, setOpen] = useState<boolean>(false)
    const [value, setValue] = useState<number | "">(props.value ? props.value.id : "")
    const loading = open && list.length === 0

    const {mode} = useFormContext()

    useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        (
            async () => await getData<BidAttributesValues>(new URL(`attributes/${props.attribute.id}/attributes_values`, BASE_URL), 0, 1000, setList)
        )()

        return () => {
            active = false;
        };
    }, [loading]);

    function handleChange(e: SyntheticEvent, value: any) {
        value ? setValue(value['id']) : setValue("")
    }

    return (
        <FieldRenderer
            label={
                props.attribute.required ? <>
                    {props.attribute.name} {props.attribute.suffix_after && `${props.attribute.name} (${props.attribute.suffix_after})` }
                    <Typography
                        component='span'
                        color='primary'>
                        *
                    </Typography>
                </> : props.attribute.name
            }
            text={props.value ? props.value.name : ""}
            editComponent={
                <>
                    <Input
                        value={value}
                        type='number'
                        name={props.attribute.id.toString()}
                        sx={{display: 'none'}}
                        inputProps={{datatype: 'oneMany'}}
                    />
                    <Autocomplete
                        options={list}
                        onOpen={() => setOpen(true)}
                        onClose={() => {
                            setOpen(false);
                        }}
                        open={open}
                        defaultValue={props.value}
                        getOptionLabel={(option) => option.name || ''}
                        onChange={handleChange}
                        renderInput={(params) =>
                            <TextField
                                required={props.attribute.required}
                                variant='standard'
                                placeholder={props.attribute.placeholder}
                                {...params}
                            />}
                        clearOnEscape
                        fullWidth
                    />
                </>
            }
            readonly={mode === "view"}
        />
    );
}
