import {MembershipFull} from "../../../client";
import {BASE_URL, deleteOptions, PostOptions} from "../../../Requests/requests";
import {Button, Chip, CircularProgress, Dialog, DialogContent} from "@mui/material";
import {
    BuildCircle,
    ChangeCircle,
    DeleteForeverRounded,
    PendingRounded,
    PlayCircle,
    Schedule,
    StopCircle,
    TimerOff
} from "@mui/icons-material"
import {Context, useContext, useMemo, useState} from "react";
import {Action, Column} from "@material-table/core";
import {TFetchContext, TFormField} from "../../../types";
import {ListRecords} from "../../special/list_view";
import {rMembershipPlan} from "../../../Requests/backend_routes";
import {AdminFormSample} from "../../form/AdminFormSample";
import {FormContextProvider} from "../../../context/FormContext";
import {AdminForm} from "../../form/AdminForm";


type TMemberShipListProps = {
    storeId: string
    ctx: Context<TFetchContext<MembershipFull>>
}

export function StoreMemberships(props: TMemberShipListProps): JSX.Element {

    const {fetchForCUD, loading} = useContext(props.ctx)

    const [terminateDialog, setTerminateDialog] = useState({open: false, id: NaN});
    const [switchDialog, setSwitchDialog] = useState<{ open: boolean, rowData?: MembershipFull }>({
        open: false,
        rowData: undefined
    });
    const [activateDialog, setActivateDialog] = useState({open: false, id: NaN});
    const [deleteDialog, setDeleteDialog] = useState({open: false, id: NaN});
    const [editDialog, setEditDialog] = useState<{ open: boolean, rowData?: MembershipFull }>({
        open: false,
        rowData: undefined
    });
    const [addDialog, setAddDialog] = useState(false);

    const columns = useMemo((): Column<MembershipFull>[] => {
        return [
            {
                title: 'ID',
                field: 'id',
                hidden: true
            },
            {
                title: 'Qty',
                field: 'quantity',
                hidden: true
            },
            {
                title: 'active',
                field: 'active',
                hidden: true
            },
            {
                title: 'Plan',
                field: 'plan.name',
                width: '5%'
            },
            {
                title: 'Début programmé',
                field: 'scheduled_to_start',
                width: '16%'
            },
            {
                title: 'Fin programmé',
                field: 'scheduled_to_end',
                width: '16%'
            },
            {
                title: 'Activé le',
                field: 'activated_date',
                render: ({activated_date, terminated_date}) => {
                    return (
                        <>
                            {!activated_date && <Chip color='disabled' label='Jamais'/>}
                            {activated_date && !terminated_date && <Chip color='primary' label={activated_date}/>}
                            {activated_date && terminated_date && <Chip color='disabled' label={activated_date}/>}
                        </>
                    )
                }
            },
            {
                title: 'Terminée le',
                field: 'terminated_date',
                defaultSort: 'asc',
                render: ({active, terminated_date}) => {
                    return (
                        <>
                            {!terminated_date && active && (<><Chip color="success" label="En cours"/> <PendingRounded
                                color="success"/></>)}
                            {!terminated_date && !active && (<><Chip color="warning" label="Programé"/> <Schedule
                                color="warning"/></>)}
                            {terminated_date && (<><Chip color="disabled" label={terminated_date}/> <TimerOff
                                color="disabled"/></>)}
                        </>
                    )
                }
            },
            {
                title: 'Jours en crédit',
                field: 'credit_days',
                width: '10%',
                hidden: true
            }
        ]
    }, [])
    const formFields: TFormField<MembershipFull>[] = useMemo((): TFormField<MembershipFull>[] => {
        return [
            {
                field: 'plan_id',
                label: "Plan d'abonnement",
                placeHolder: "Séléctionner le type d'abonnement",
                type: 'many2one',
                position: 'Left',
                order: 1,
                create: 'required',
                update: 'optional',
                relationalAPI: rMembershipPlan,
                localObject: 'plan',
                localValue: 'name',
                externalLabelKey: "name"
            },
            {
                field: 'quantity',
                label: 'Quantité',
                placeHolder: 'Séléectionner la quantité pour ce plan',
                type: 'number',
                position: 'Left',
                order: 2,
                create: 'required',
                update: 'optional'
            },
            {
                field: 'scheduled_to_start',
                label: 'Début prévu',
                placeHolder: 'La date de début',
                type: 'date',
                position: 'Left',
                order: 3,
                create: 'required',
                update: 'optional'
            }
        ]
    }, [])

    // Terminate function
    const terminate = async () => {
        const API = new URL(`memberships/${props.storeId}/terminate?membership_id=${terminateDialog.id}`, BASE_URL);
        try {
            await fetchForCUD(API, PostOptions(), 'Abonnement terminée')
        } catch (error) {

        }
        setTerminateDialog({open: false, id: NaN})
    }

    // Activate function
    const activate = async () => {
        const API = new URL(`memberships/${props.storeId}/activate?membership_id=${activateDialog.id}`, BASE_URL);
        try {
            await fetchForCUD(API, PostOptions(), 'Abonnement activée')
        } catch (error) {

        }
        setActivateDialog({open: false, id: NaN})
    }

    // Cancel function
    const deleteMembership = async () => {

        const API = new URL(`memberships/${props.storeId}/delete?membership_id=${deleteDialog.id}`, BASE_URL);
        try {
            await fetchForCUD(API, deleteOptions(), 'Abonnement supprimée')
        } catch (error) {

        }
        setDeleteDialog({open: false, id: NaN})
    }

    return <>
        {/* Terminate Dialog */}
        <Dialog open={terminateDialog.open} onClose={() => setTerminateDialog({open: false, id: NaN})}>
            <DialogContent>
                <p> Cette opération va arrêter complètement l'abonnement actuel </p>
                <p><strong>Note:</strong><i> Les jours restant seront comptés</i></p>
                {loading && <CircularProgress/>}
                {!loading && <Button variant="contained" color="error" onClick={terminate}> Continue </Button>}
                <Button variant="outlined" color="primary"
                        onClick={() => setTerminateDialog({open: false, id: NaN})}> Retour </Button>
            </DialogContent>
        </Dialog>

        {/* Activate Dialog */}
        <Dialog open={activateDialog.open} onClose={() => setActivateDialog({open: false, id: NaN})}>
            <DialogContent>
                <p> Cette opération va démarrer l'abonnement actuel </p>
                {loading && <CircularProgress/>}
                {!loading && <Button variant="contained" color="success" onClick={activate}> Continue </Button>}
                <Button variant="outlined" color="primary"
                        onClick={() => setActivateDialog({open: false, id: NaN})}> Retour </Button>
            </DialogContent>
        </Dialog>

        {/* Switch Plan Dialog */}
        <Dialog open={switchDialog.open} onClose={() => setSwitchDialog({open: false, rowData: undefined})} fullWidth maxWidth='xl'>
            <DialogContent>
                    <p> Cette opération va arréter completement l'abonnement actuel et créer une autre avec le plan
                        sélectionner au dessus</p>
                    <p> Les jours restant de l'abonnement actuel seront convertis et assignés automatiquement au nouvel
                        abonnement </p>
                    <FormContextProvider startMode='create'>
                        <AdminFormSample
                            ctx={props.ctx}
                            renderFields={[{
                                field: 'plan_id',
                                label: "Plan d'abonnement",
                                placeHolder: "Séléctionner le type d'abonnement",
                                type: 'many2one',
                                position: 'Left',
                                order: 1,
                                create: 'required',
                                update: 'optional',
                                relationalAPI: rMembershipPlan,
                                localObject: 'plan',
                                localValue: 'name',
                                externalLabelKey: "name",
                                filterFunc: (item) => switchDialog.rowData ? item.id !== switchDialog.rowData.plan_id : () => {}
                            }]}
                            fixedFields={[{field: 'id', value: switchDialog.rowData ? switchDialog.rowData.id: NaN}]}
                            successCreateMsg='Plan modifié avec succès'
                            successUpdateMsg='Plan modifié avec succès'
                            createApi={new URL(`memberships/${props.storeId}/switch?membership_id=${switchDialog.rowData ? switchDialog.rowData.id: NaN}`, BASE_URL)}
                            callBackCancel={() => setSwitchDialog({open: false, rowData: undefined})}
                            callBack={async () => setSwitchDialog({open: false, rowData: undefined})}
                        />
                    </FormContextProvider>
                    {/*<Many2OneNested
                        api={PLANS_API}
                        label='Sélectionner un plan'
                        labelKey="name"
                        valueKey="id"
                        testFunction={(v) => v.id != switchDialog.rowData.plan_id}
                        required={true}
                        name="plan_id"
                    />*/}
            </DialogContent>
        </Dialog>

        {/* Delete Dialog */}
        <Dialog open={deleteDialog.open} onClose={() => setDeleteDialog({open: false, id: NaN})}>
            <DialogContent>
                <p> Cette opération va supprimer completement l'abonnement actuel.</p>
                {loading && <CircularProgress/>}
                {!loading && <Button variant="contained" color="error" onClick={deleteMembership}> Continue </Button>}
                <Button variant="outlined" color="primary"
                        onClick={() => setDeleteDialog({open: false, id: NaN})}> Retour </Button>
            </DialogContent>
        </Dialog>

        {/* Edit Membership Dialog */}
        <Dialog open={editDialog.open} onClose={() => setEditDialog({open: false, rowData: undefined})} maxWidth='xl' fullWidth>
            <DialogContent>
                <FormContextProvider startMode='update'>
                    <AdminForm
                        id={editDialog.rowData?.id.toString()}
                        data={editDialog.rowData}
                        ctx={props.ctx}
                        renderFields={formFields}
                        successCreateMsg='Plan ajouté avec succès'
                        successUpdateMsg='Plan modifié avec succès'
                        updateApi={new URL(`memberships/${props.storeId}/${editDialog.rowData?.id}`, BASE_URL)}
                        callBackCancel={() => setEditDialog({open: false, rowData: undefined})}
                        callBack={async () => setEditDialog({open: false, rowData: undefined})}
                        isPopUp
                    />
                </FormContextProvider>
                {/*<Many2OneNested
                        api={PLANS_API}
                        label='Sélectionner un plan'
                        labelKey="name"
                        valueKey="id"
                        testFunction={(v) => v.id != switchDialog.rowData.plan_id}
                        required={true}
                        name="plan_id"
                    />*/}
            </DialogContent>
        </Dialog>

        {/* Add Membership Dialog */}
        <Dialog open={addDialog} onClose={() => setAddDialog(false)}>
            <DialogContent>
                {/*<StoreMembershipForm
                    storeId={storeId}
                    plansApi={PLANS_API}
                    onClose={() => setAddDialog(false)}
                />*/}
            </DialogContent>
        </Dialog>

        <ListRecords
            title="Abonnements"
            ctx={props.ctx}
            columns={columns}
            actions={[
                // Cancel Membership
                (rowData) => ({
                    icon: () => <DeleteForeverRounded color="error"/>,
                    tooltip: 'Supprimer',
                    hidden: rowData.terminated_date || rowData.active,
                    onClick: (event, data: MembershipFull) => setDeleteDialog({open: true, id: data.id})
                } as Action<MembershipFull>),
                // Switch Membership
                rowData => ({
                    icon: () => <ChangeCircle color="secondary"/>,
                    tooltip: 'Convertir à autre plan',
                    hidden: rowData.terminated_date || !rowData.active,
                    onClick: (event, data: MembershipFull) => setSwitchDialog({open: true, rowData: data})
                } as Action<MembershipFull>),
                // Terminate Membership
                rowData => ({
                    icon: () => <StopCircle color="error"/>,
                    tooltip: 'Terminer',
                    hidden: !rowData.active,
                    onClick: (event, data: MembershipFull) => setTerminateDialog({open: true, id: data.id})
                } as Action<MembershipFull>),
                // Edit Membership
                rowData => ({
                    icon: () => <BuildCircle color="primary"/>,
                    tooltip: 'Modifier',
                    hidden: rowData.active || rowData.terminated_date,
                    onClick: (event, data: MembershipFull) => setEditDialog({open: true, rowData: data})
                } as Action<MembershipFull>),
                // Activate Membership
                (rowData) => {
                    const compute_hidden = () => {
                        let scheduled_to_start_date = new Date(rowData.scheduled_to_start as string)
                        let today = new Date();
                        return rowData.active || rowData.terminated_date || today < scheduled_to_start_date
                    }
                    return {
                        icon: () => <PlayCircle color="success"/>,
                        tooltip: 'Activer',
                        hidden: compute_hidden(),
                        onClick: (event, data: MembershipFull) => setActivateDialog({open: true, id: data.id})
                    } as Action<MembershipFull>
                },
            ]}
            creatable={{
                type: 'popup',
                formProps: {
                    ctx: props.ctx,
                    createApi: new URL(`memberships/${props.storeId}/new`, BASE_URL),
                    renderFields: formFields,
                    fixedFields: [{field: 'store_id', value: props.storeId}],
                    successCreateMsg: 'Abonnement créée avec succès',
                    successUpdateMsg: 'Abonnement modifier avec succès'
                }
            }}
        />
    </>
}
