import * as React from 'react';
import {PropsWithChildren} from 'react';
import Tab, {TabProps} from '@mui/material/Tab';
import Tabs, {TabsProps} from '@mui/material/Tabs';
import {styled} from '@mui/material/styles';
import {MTableToolbar} from "@material-table/core";

const TabOverride = styled(Tab)(({theme}) => ({
    '&': {
        color: `rgba(0,0,0 / 60%)`,
        backgroundColor: '#FFF',
        textTransform: 'none',
        position: 'initial',
        borderRadius: '10px 10px 0 0'
    },

    '&.Mui-selected': {
        backgroundColor: `${theme.palette.primary.main}`,
        color: '#FFF',
        boxShadow: `${theme.shadows[10]}`,
        position: 'relative',
    },

}))

export const AppTab = ({...props}: TabProps) => {
    return (
        <TabOverride {...props} />
    )
}

const TabsOverride = styled(Tabs)(() => ({
    '&': {
        padding: '0'
    },

}))

export const AppTabs = ({children, ...props}: TabsProps) => {
    return (
        <TabsOverride
            visibleScrollbar={false}
            {...props}
        >
                {children}
        </TabsOverride>
    )
}

const ToolbarOverride = styled(MTableToolbar)(() => ({

    '&': {
        background: 'none'
    }

}))

export const AppMTableToolbar = ({children, ...props}: PropsWithChildren<any>) => {
    return (
        <ToolbarOverride {...props}>
            {children}
        </ToolbarOverride>
    )
}
