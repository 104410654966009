import * as React from 'react';
import {styled} from '@mui/material/styles';
import {Alert, Snackbar} from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar, {AppBarProps as MuiAppBarProps} from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Grid from '@mui/material/Grid';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import DashboardIcon from '@mui/icons-material/Dashboard';
import {Logout} from '@mui/icons-material';
import {Link, Route, Routes} from 'react-router-dom';
import '../../fonts/baloo-2-v16-latin-regular.ttf'
import useAuth from "../../hooks/useAuth";
import {Users} from "./users/users";
import {DomainTypes} from "./domain_types/domain_types";
import {Countries} from "./countries/countries";
import {AttributesGroups} from "./attributes_groups/attributes_groups";
import {Colors} from "./colors/colors";
import {PriceTypes} from "./price_types/price_types";
import {SaleTypes} from "./sale_types/sale_types";
import {StockStates} from "./stock_states/stock_states";
import {StoreTypes} from "./store_types/store_types";
import {MembershipPlans} from "./membership_plans/membership_plans";
import {Categories} from "./categories/categories";
import {Stores} from "./stores/stores";
import {Bids} from "./bids/bids";
import {Domains} from "./domains/domains";
import {DomainController} from "./domains/domain_controller";
import {UserController} from "./users/user_controller";
import {CategoryController} from "./categories/category_controller";
import {StoreController} from "./stores/store_controller";
import {AttributeController} from "./attributes/attribute_controller";
import {AttributeValueController} from "./attributes_values/attribute_value_controller";
import {SystemNotifications} from "./system_notification/system_notifications";
import {SystemNotificationController} from "./system_notification/system_notification_controller";
import {useNavigate} from "react-router";
import {BidController} from "./bids/bid_controller";

const drawerWidth = 170;

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(2),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(2),
                },
            }),
        },
    }),
);

function DashboardContent() {

    const [open, setOpen] = React.useState(true);

    const {snackOpen, setSnackOpen, responseText, responseType, setUser} = useAuth()
    const toggleDrawer = () => {
        setOpen(!open);
    };

    const navigate = useNavigate()

    return (
        <>
            <CssBaseline/>
            <Box sx={{display: "flex"}}>
                <Snackbar
                    anchorOrigin={{vertical: "top", horizontal: "right"}}
                    open={snackOpen}
                    autoHideDuration={6000}
                    onClose={() => setSnackOpen(false)}
                >
                    <Alert
                        onClose={() => setSnackOpen(false)}
                        severity={responseType}
                        sx={{width: "100%"}}
                    >
                        {responseText}
                    </Alert>
                </Snackbar>

                <Drawer variant="permanent" open={open}>
                    <Toolbar
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            px: [1],
                        }}
                    >
                        <Link to={'/'}>
                            <img src="dzk.png" width='100%' height='100%' alt="DZKingdom"/>
                        </Link>
                        <IconButton onClick={toggleDrawer}>
                            <ChevronLeftIcon/>
                        </IconButton>
                    </Toolbar>
                    <Divider/>
                    <List component="nav">

                        {/* Users */}
                        <ListSubheader component="div" inset>
                            Gestion des utilisateurs
                        </ListSubheader>

                        <ListItemButton onClick={() => navigate('/users')}>
                            <ListItemIcon>
                                <DashboardIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Utilisateurs"/>
                        </ListItemButton>

                        {/* Countries */}
                        <ListSubheader component="div" inset>
                            Pays
                        </ListSubheader>
                        <ListItemButton onClick={() => navigate('/countries')}>
                            <ListItemIcon>
                                <DashboardIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Pays"/>
                        </ListItemButton>

                        {/* Domains */}
                        <ListSubheader component="div" inset>
                            Domains
                        </ListSubheader>
                        <ListItemButton onClick={() => navigate('/domain_types')}>
                            <ListItemIcon>
                                <DashboardIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Types de domains"/>
                        </ListItemButton>

                        <ListItemButton onClick={() => navigate('/domains')}>
                            <ListItemIcon>
                                <DashboardIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Domains"/>
                        </ListItemButton>

                        {/* Attributes */}
                        <ListSubheader component="div" inset>
                            Categories
                        </ListSubheader>
                        <ListItemButton onClick={() => navigate('/categories')}>
                            <ListItemIcon>
                                <DashboardIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Categories"/>
                        </ListItemButton>

                        <ListItemButton onClick={() => navigate('/attributes_groups')}>
                            <ListItemIcon>
                                <DashboardIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Groups des attributs"/>
                        </ListItemButton>

                        {/* Stores */}
                        <ListSubheader component="div" inset>
                            Stores
                        </ListSubheader>

                        <ListItemButton onClick={() => navigate('/stores')}>
                            <ListItemIcon>
                                <DashboardIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Stores"/>
                        </ListItemButton>
                        <ListItemButton onClick={() => navigate('/store_types')}>
                            <ListItemIcon>
                                <DashboardIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Types de store"/>
                        </ListItemButton>

                        {/* Membership Plans */}
                        <ListItemButton onClick={() => navigate('/membership_plans')}>
                            <ListItemIcon>
                                <DashboardIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Plans d'abonnement"/>
                        </ListItemButton>

                        {/* Bids Separator*/}
                        <ListSubheader component="div" inset>
                            Annonces
                        </ListSubheader>

                        {/* Bids */}
                        <ListItemButton onClick={() => navigate('/bids')}>
                            <ListItemIcon>
                                <DashboardIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Annonces"/>
                        </ListItemButton>

                        {/* Colors */}
                        <ListItemButton onClick={() => navigate('/colors')}>
                            <ListItemIcon>
                                <DashboardIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Coleurs"/>
                        </ListItemButton>

                        {/* Sale Types */}
                        <ListItemButton onClick={() => navigate('/sale_types')}>
                            <ListItemIcon>
                                <DashboardIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Types de vente"/>
                        </ListItemButton>

                        {/* Price Types */}
                        <ListItemButton onClick={() => navigate('/price_types')}>
                            <ListItemIcon>
                                <DashboardIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Types de prix"/>
                        </ListItemButton>

                        {/* Stock State */}
                        <ListItemButton onClick={() => navigate('/stock_states')}>
                            <ListItemIcon>
                                <DashboardIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Etats de stock"/>
                        </ListItemButton>

                        {/* Notifications */}
                        <ListSubheader component="div" inset>
                            Notifications
                        </ListSubheader>

                        <ListItemButton onClick={() => navigate('/system_notifications')}>
                            <ListItemIcon>
                                <DashboardIcon/>
                            </ListItemIcon>
                            <ListItemText primary="System notifications"/>
                        </ListItemButton>

                    </List>
                </Drawer>
                <AppBar position="absolute" open={open}>
                    <Toolbar
                        sx={{
                            pr: "24px", // keep right padding when drawer closed
                        }}
                    >
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={toggleDrawer}
                            sx={{
                                marginRight: "36px",
                                ...(open && {display: "none"}),
                            }}
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Typography
                            component="h1"
                            variant="h6"
                            color="inherit"
                            noWrap
                            sx={{flexGrow: 1}}
                        >
                            Dashboard
                        </Typography>
                        <IconButton
                            color="inherit"
                            onClick={() => {
                                localStorage.clear()
                                setUser(false);
                            }}
                        >
                            <Badge color="secondary">
                                <Logout/>
                            </Badge>
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === "light"
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: "100vh"
                    }}
                >
                    <Toolbar/>
                    <Grid container spacing={2} margin='15px' maxWidth='95%'>
                        <Grid item xs={12}>
                            <Routes>
                                <Route path="/users" element={<Users/>}/>
                                <Route path="/users/:id" element={<UserController/>}/>
                                <Route path="/countries" element={<Countries/>}/>
                                <Route path="/domain_types" element={<DomainTypes/>}/>
                                <Route path="/domains" element={<Domains/>}/>
                                <Route path="/domains/:id" element={<DomainController/>}/>
                                <Route path="/attributes_groups" element={<AttributesGroups/>}/>
                                <Route path="colors" element={<Colors/>}/>
                                <Route path="/price_types" element={<PriceTypes/>}/>
                                <Route path="/sale_types" element={<SaleTypes/>}/>
                                <Route path="/stock_states" element={<StockStates/>}/>
                                <Route path="/store_types" element={<StoreTypes/>}/>
                                <Route path="/membership_plans" element={<MembershipPlans/>}/>
                                <Route path="/categories" element={<Categories/>}/>
                                <Route path="/categories/:id" element={<CategoryController/>}/>
                                <Route path="/categories/:catId/attributes/:id" element={<AttributeController/>}/>
                                <Route path="/categories/:catId/attributes/:attrId/values/:id"
                                       element={<AttributeValueController/>}/>
                                <Route path="/stores" element={<Stores/>}/>
                                <Route path="/stores/:id" element={<StoreController/>}/>
                                <Route path="/bids" element={<Bids/>}/>
                                <Route path="/bids/:id" element={<BidController/>}/>
                                <Route path="/system_notifications" element={<SystemNotifications/>}/>
                                <Route path="/system_notifications/:id" element={<SystemNotificationController/>}/>
                            </Routes>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </>
    )
}

export default function Dashboard() {
    return <DashboardContent/>;
}
