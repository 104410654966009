import {Box, Card, CardMedia} from "@mui/material"
import {Cancel, Delete, Image, PhotoCamera} from "@mui/icons-material"
import {FormEvent, useEffect, useRef, useState} from "react"
import {PictureWidgetProps} from "../../../types";
import {IMAGES_URL} from "../../../Requests/requests";
import {useFormContext} from "../../../context/FormContext";


export const Picture = ({inputName, defaultValue, unmountable}: PictureWidgetProps) => {

    const [image, setImage] = useState<string | URL | undefined>(
        defaultValue ? new URL(defaultValue, IMAGES_URL) : undefined
    )
    const {mode} = useFormContext()

    const fileInputRef = useRef({} as HTMLInputElement)

    useEffect(() => {
        setImage(
            defaultValue ? new URL(defaultValue, IMAGES_URL) : undefined
        )
    }, [mode])

    async function handleFileChange(e: FormEvent<HTMLLabelElement>) {
        const fileInput: HTMLInputElement = e.currentTarget.control as HTMLInputElement
        if (fileInput.getAttribute('none') !== null) fileInput.removeAttribute('none')
        const files: FileList = fileInput.files as FileList
        if (files.length <= 0) return
        const imgFile: File = files[0]
        setImage(URL.createObjectURL(imgFile))
    }

    async function handleDelete(e: FormEvent<HTMLLabelElement>) {
        e.preventDefault();
        const fileInput: HTMLInputElement = e.currentTarget.control as HTMLInputElement
        fileInput.setAttribute('none', '')
        setImage(undefined)
    }

    function handleUnmount(e: FormEvent<HTMLLabelElement>) {
        const container: HTMLElement = e.currentTarget.parentElement?.parentElement as HTMLElement
        container.remove()
    }

    return (
        <Card
            sx={{maxWidth: 200, height: 200, position: "relative", alignSelf: "flex-end", width: "100%"}}
        >
            {image && (
                <CardMedia
                    sx={{objectFit: "contain", height: "100%"}}
                    src={image instanceof URL ? image.toString() : image}
                    component="img"
                />
            )}

            {!image && (
                <Image sx={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: '#bdbdbd',
                    color: "white",
                    objectFit: "fill",
                }}
                />
            )}

            {mode !== "view" && (
                <Box
                    position="absolute"
                    bottom={0}
                    width="inherit"
                    bgcolor="rgba(0,0,0,0.4)"
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    minWidth="100%"
                >
                    <label onChange={handleFileChange} htmlFor={`file-${inputName}`}>
                        <input
                            id={`file-${inputName}`}
                            name={inputName}
                            type="file"
                            accept="image/*"
                            ref={fileInputRef}
                            alt={defaultValue}
                            hidden
                        />
                        <PhotoCamera sx={{color: "rgba(255,255,255, 0.8)", display: "flex"}}/>
                    </label>

                    {!unmountable && (
                        <label onClick={handleDelete} htmlFor={`file-${inputName}`}>
                            <Delete sx={{color: "rgba(255,255,255, 0.8)", display: "flex"}}/>
                        </label>
                    )}

                    {unmountable && (
                        <label onClick={handleUnmount}>
                            <Cancel sx={{color: "rgba(255,255,255, 0.8)", display: "flex"}}/>
                        </label>
                    )}

                </Box>
            )}
        </Card>
    )
}
