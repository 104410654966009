import {Reducer} from "./types";

export function formStore<T>(): Reducer<T> {
    return {
        object: undefined,
        subscribers: [],
        setObject(newObject: T) {
            this.object = newObject;
            this.subscribers.forEach(callback => callback(this.object));
        },
        subscribe(callback) {
            this.subscribers.push(callback);
            return () => {
                this.subscribers = this.subscribers.filter(cb => cb !== callback);
            };
        },
    }
}
