import React, {useEffect, useState} from "react";
import {Checkbox, CircularProgress, FormControl, FormControlLabel} from "@mui/material";
import {WidgetMany2ManyProps} from "../../../types";
import {getData} from "../../../context/FetchContext";
import {useFormContext} from "../../../context/FormContext";
import {ModelCommon} from "../../../client";


export function Many2Many<T extends { [key: string]: any }>
(
    {
        item,
        defaultSelected
    }: WidgetMany2ManyProps<T>
) {

    const [list, setList] = useState<readonly ModelCommon[] | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(true)

    const {mode} = useFormContext()

    const readonly = mode === "view" ? true : item[mode] === 'readonly'
    const hidden = mode === "view" ? item.hidden : item[mode] === 'hidden'

    useEffect(
        () => {
            (async () => await getData<T>(item.relationalAPI, 0, 1000, setList))()
            setLoading(false)
        }, []
    );

    if (loading) return <CircularProgress/>

    if (!hidden) {

        return (
            <div style={{position: "relative"}}>
                <FormControl component="fieldset" fullWidth>
                    <h4 style={{margin: '5px'}}> {item.label} </h4>
                    <div>
                        {
                            list?.map(
                                (record) =>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                defaultChecked={
                                                    !!defaultSelected?.find((item) => item.id === record.id)
                                                }
                                                name={item.field.toString()}
                                                value={record.id}
                                                disabled={readonly}
                                            />
                                        }
                                        label={record.name}
                                        key={record.id}
                                    />
                            )
                        }
                    </div>
                </FormControl>
            </div>
        );
    }
    return <></>

}
