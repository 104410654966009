import {FC} from "react";
import {Image} from "@mui/icons-material";
import {IMAGES_URL} from "Requests/requests";
import createGenericContext from "context/FetchContext";
import {rSystemNotifications} from "../../../Requests/backend_routes";
import {SystemNotification} from "../../../client";
import {Column} from "@material-table/core";
import {ListRecords} from "../../special/list_view";
import {useNavigate} from "react-router";

const {MyContext, FetchContextProvider} = createGenericContext<SystemNotification>({getApi: rSystemNotifications, defaultT: []})

export const SystemNotifications: FC = () => {
    const navigate = useNavigate()
    const columns: Column<SystemNotification>[] = [
        { title: "ID", field: "id", editable: "never", hidden: true },
        {
            field: "icon",
            title: "Icon",
            render: (rowData) => {
                return (
                    <>
                        {rowData.icon && (
                            <img
                                src={`${IMAGES_URL}/${rowData.icon}`}
                                style={{ width: 30, height: 30, objectFit: 'fill' }}
                            />
                        )}

                        {!rowData.icon && (
                            <Image
                                sx={{ width: 30, height: 30, objectFit: 'fill' }}
                            />
                        )}
                    </>
                );
            },
        },
        { title: "Titre", field: "title", width: '35%'},
        {
            title: "Objet",
            field: "object_type",
            width: '5%'
        },
        {
            title: "Objet_ID",
            field: "object_type_id",
            width: '5%'
        },
        {
            title: 'Date créé',
            field: 'created_date',
            type: 'datetime'
        },
        {
            title: 'Envoyée le',
            field: 'sent_date',
            type: 'datetime'
        }
    ];

    return (
        <FetchContextProvider>
            <ListRecords<SystemNotification>
                title="System notifications"
                ctx={MyContext}
                creatable={{type: 'newWindow', url: '/system_notifications/new'}}
                columns={columns}
                options={{grouping: true, pageSizeOptions: [100, 200, 500], pageSize: 100}}
                onRowClick={(event, rowData) => navigate(`/system_notifications/${rowData?.id}`)}
            />
        </FetchContextProvider>
    )
}
