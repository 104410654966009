import createGenericContext from "../../../context/FetchContext";
import {Category, DomainFull} from "../../../client";
import {rCategories} from "../../../Requests/backend_routes";
import {BASE_URL} from "../../../Requests/requests";
import React, {SyntheticEvent, useEffect, useState} from "react";
import {X2Many} from "../../special/x2many";
import {Column} from "@material-table/core";
import {nested_name_render} from "../../../tools/tools";
import {AppTab, AppTabs} from "../../overrides";
import {a11yProps, TabPanel} from "../../special/tab_panel";
import {Bids} from "../bids/bids";
import {Reducer} from "../../../types";
import {Stores} from "../stores/stores";


type TDomainTabsController = { id: string, domainReducer: Reducer<DomainFull> }

export function DomainTabsController({id, domainReducer}: TDomainTabsController): JSX.Element {

    const [tabValue, setTabValue] = useState<number>(0)
    const [domain, setDomain] = useState<DomainFull | undefined>(undefined)
    const handleTabChange = (event: SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    useEffect(() => {
        const unsubscribe = domainReducer.subscribe(
            (object) => {
                domain === undefined && setDomain(object)
            }
        );
        return () => unsubscribe();
    }, [])

    if (id === "new") return <></>

    // Many2Many domain / category
    const {
        MyContext: CategoriesContext,
        FetchContextProvider: CategoriesContextProvider
    } = createGenericContext<Category>(
        {
            getApi: rCategories,
            defaultT: []
        }
    )

    const {
        MyContext: DomainCategoriesContext,
        FetchContextProvider: DomainCategoriesContextProvider
    } = createGenericContext<Category>(
        {
            getApi: new URL(`domains/${id}/categories`, BASE_URL),
            defaultT: []
        }
    )

    const DomainsCategoriesSaveApi: URL = new URL(`domains/${id}/categories/assign`, BASE_URL)
    const categoriesColumns: Column<Category>[] = [

        {title: "ID", field: "id", width: '1%'},
        {title: 'Ordre', field: 'sequence', defaultSort: 'asc', width: '1%'},
        {
            title: "Nom",
            field: "name",
            width: '90%',
            render: (rowData) => nested_name_render(rowData)
        },
        {
            title: "Créé le",
            field: "created_date",
            type: "date",
            grouping: false,
            filtering: false,
            hidden: true,
            width: '15%'
        },
        {
            title: "Dernière modif",
            field: "updated_date",
            type: "date",
            grouping: false,
            filtering: false,
            hidden: true,
            width: '15%'
        }
    ];

    return (
        <>
            {domain && (
                <>
                    <AppTabs value={tabValue} onChange={handleTabChange}>
                        <AppTab label="Stores" {...a11yProps(0)} />
                        <AppTab label="Catégories" {...a11yProps(1)} />
                        <AppTab label="Annonces" {...a11yProps(2)} />
                    </AppTabs>

                    <TabPanel value={tabValue} index={0}>
                        <Stores
                            title={`${domain?.name} (stores)`}
                            defaultApi={new URL(`domains/${domain?.id}/stores`, BASE_URL)}
                            from="domain"
                        />
                    </TabPanel>

                    <TabPanel value={tabValue} index={1}>
                        <CategoriesContextProvider>
                            <DomainCategoriesContextProvider>
                                <X2Many
                                    title='Catégories'
                                    dataCtx={CategoriesContext}
                                    selectedCtx={DomainCategoriesContext}
                                    saveApi={DomainsCategoriesSaveApi}
                                    columns={categoriesColumns}
                                    successMsg='Catégories assignées avec succès'
                                    parentChildData={(row, rows) =>
                                        rows.find((a) => a.id === row.parent_id)
                                    }
                                    options={{
                                        selection: true,
                                        pageSize: 50,
                                        pageSizeOptions: [50, 100, 200]
                                    }}
                                />
                            </DomainCategoriesContextProvider>
                        </CategoriesContextProvider>
                    </TabPanel>

                    <TabPanel value={tabValue} index={2}>
                        <Bids
                            title={`${domain?.name} annonces`}
                            defaultApi={new URL(`domains/${domain?.id}/bids`, BASE_URL)}
                            from="domain"
                        />
                    </TabPanel>
                </>
            )}

        </>
    )
}
