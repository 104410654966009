import React from "react";
import {useParams} from "react-router-dom";
import createGenericContext from "../../../context/FetchContext";
import {AttributeFull} from "../../../client";
import {rAttributes} from "../../../Requests/backend_routes";
import {BASE_URL} from "../../../Requests/requests";
import {TFormField} from "../../../types";
import {AdminFormSample} from "../../form/AdminFormSample";
import {FormContextProvider} from "../../../context/FormContext";
import {AttributeTabsController} from "./attribute_tabs_controller";
import {formStore} from "../../../formStore";


export function attributesFields(catId: string): TFormField<AttributeFull>[] {
    return [
        {
            field: 'name',
            label: 'Nom',
            placeHolder: "Nom de l'attribut",
            create: 'required',
            update: 'optional',
            type: 'text',
            position: 'Left',
            order: 1
        },
        {
            field: 'display_type',
            label: "Type d'affichage",
            placeHolder: "Type d'affichage dans l'application (ManyOne, ManyMany ... etc)",
            create: 'required',
            update: 'optional',
            type: 'text',
            position: 'Left',
            order: 2
        },
        {
            field: 'attribute_group_id',
            label: "Group",
            placeHolder: "Group de l'attribut (utiliser lors la création d'une nouvelle annonce)",
            create: 'optional',
            update: 'optional',
            type: 'many2one',
            localObject: 'group',
            localValue: 'name',
            externalLabelKey: 'name',
            relationalAPI: new URL(`categories/${catId}/attributes_groups`, BASE_URL),
            position: 'Left',
            order: 3
        },
        {
            field: 'placeholder',
            label: "Placeholder",
            placeHolder: "Text explicative (utiliser lors la création d'une nouvelle annonce)",
            create: 'optional',
            update: 'optional',
            type: 'text',
            position: 'Left',
            order: 4
        },
        {
            field: 'suffix_before',
            label: "Suffix avant",
            placeHolder: "Suffix avant (utilisé dans les attributs avec unités de measures)",
            create: 'optional',
            update: 'optional',
            type: 'text',
            position: 'Left',
            order: 5
        },
        {
            field: 'suffix_after',
            label: "Suffix après",
            placeHolder: "Suffix après (utilisé dans les attributs avec unités de measures)",
            create: 'optional',
            update: 'optional',
            type: 'text',
            position: 'Left',
            order: 6
        },
        {
            field: 'sequence',
            label: "Ordre",
            placeHolder: "Ordre de l'attribut (utilisé pour définir la periorité des attributs)",
            create: 'optional',
            update: 'optional',
            type: 'number',
            position: 'Left',
            order: 7
        },
        {
            field: 'required',
            label: "Obligatoire",
            create: 'optional',
            update: 'optional',
            type: 'boolean',
            position: 'Right',
            order: 1
        },
        {
            field: 'is_custom',
            label: "Custom valeur",
            create: 'optional',
            update: 'readonly',
            type: 'boolean',
            position: 'Right',
            order: 2
        },
        {
            field: 'in_title',
            label: "Dans le title d'annonce",
            create: 'optional',
            update: 'optional',
            type: 'boolean',
            position: 'Right',
            order: 3
        },
        {
            field: 'featured',
            label: "En vedette",
            create: 'optional',
            update: 'optional',
            type: 'boolean',
            position: 'Right',
            order: 4
        },
        {
            field: 'scroll',
            label: "Scroll",
            create: 'optional',
            update: 'optional',
            type: 'boolean',
            position: 'Right',
            order: 5
        },
        {
            field: 'number_only',
            label: "Digital Uniquement",
            create: 'optional',
            update: 'optional',
            type: 'boolean',
            position: 'Right',
            order: 6
        },

    ]
}

const attributeStore = formStore<AttributeFull>()

export function AttributeController(): JSX.Element {

    const {catId, id} = useParams();

    const {
        MyContext,
        FetchContextProvider
    } = createGenericContext<AttributeFull>(
        {
            getApi: new URL(`${rAttributes.pathname}/${id}`, BASE_URL),
            defaultT: undefined
        }
    )

    const fields = attributesFields(catId as string)

    return (
        <>
            <FetchContextProvider>
                <FormContextProvider startMode={id === "new" ? 'create' : undefined}>
                    <AdminFormSample<AttributeFull>
                        id={id as string}
                        baseApi={rAttributes}
                        renderFields={fields}
                        fixedFields={[{field: 'category_id', value: catId}]}
                        ctx={MyContext}
                        successCreateMsg="Attribut ajoutée avec succès"
                        successUpdateMsg="Attribut modifiée avec succès"
                        reducer={attributeStore}
                    />
                </FormContextProvider>
            </FetchContextProvider>
            {id !== "new" && (
                <AttributeTabsController id={id as string} catId={catId as string} reducer={attributeStore} />
            )}
        </>
    )
}
