import React, {useEffect, useState} from "react";
import {Checkbox, CircularProgress, FormControl, FormControlLabel} from "@mui/material";
import {WidgetMany2ManyColorsProps} from "../../../types";
import {getData} from "../../../context/FetchContext";
import {useFormContext} from "../../../context/FormContext";
import {Color} from "../../../client";


export function Many2ManyColors<T extends { [key: string]: any }>
(
    {
        item,
        defaultSelected
    }: WidgetMany2ManyColorsProps<T>
) {

    const [list, setList] = useState<readonly Color[] | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(true)

    const {mode} = useFormContext()

    const readonly = mode === "view" ? true : item[mode] === 'readonly'
    const hidden = mode === "view" ? item.hidden : item[mode] === 'hidden'

    useEffect(
        () => {
            (async () => await getData<T>(item.relationalAPI, 0, 1000, setList))()
            setLoading(false)
        }, []
    );

    if (loading) return <CircularProgress/>

    if (!hidden) {

        return (
            <div style={{position: "relative"}}>
                <FormControl component="fieldset" fullWidth>
                    <h4 style={{margin: '5px'}}> {item.label} </h4>
                    <div>
                        {
                            list?.map(
                                (record) =>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                defaultChecked={
                                                    !!defaultSelected?.find((item) => item.id === record.id)
                                                }
                                                name={item.field.toString()}
                                                value={record.id}
                                                disabled={readonly}
                                            />
                                        }
                                        label={
                                            <>
                                                {record.title}
                                                <span
                                                    style={{
                                                        backgroundColor: record.hex,
                                                        width: '100%',
                                                        height: '10px',
                                                        display: 'block',
                                                        border: '1px solid'
                                                    }}>
                                        </span>
                                            </>
                                        }
                                        key={record.id}
                                    />
                            )
                        }
                    </div>
                </FormControl>
            </div>
        );
    }
    return <></>

}
