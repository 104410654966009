import {ListRecords} from "components/special/list_view"
import createGenericContext from "context/FetchContext";
import {Column} from "@material-table/core";
import {DomainType} from "../../../client";
import {rDomainTypes} from "../../../Requests/backend_routes";


const CREATE_MSG = "Type ajouté avec succès"
const UPDATE_MSG = "Type modifié avec succès"
const DELETE_MSG = "Type supprimé avec succès"

const {MyContext, FetchContextProvider} = createGenericContext<DomainType>({getApi: rDomainTypes, defaultT: []})

export function DomainTypes(): JSX.Element {

    const columns: Column<DomainType>[] = [
        {title: "ID", field: "id", editable: 'never', width: '1%'},
        {title: 'Ordre', field: 'sequence', defaultSort: 'asc', width: '1%'},
        {title: "Nom", field: "name"},
        {title: "Créé le", field: "created_date", type: "date", hidden: true},
        {title: "Dernière modif", field: "updated_date", type: "date", hidden: true}
    ]


    return (
        <FetchContextProvider>
            <ListRecords<DomainType>
                title="Types de domain"
                ctx={MyContext}
                columns={columns}
                creatable={{type: 'inline', url: 'domain_types/new', msg: CREATE_MSG}}
                updatable={{type: 'inline', url: 'domain_types', msg: UPDATE_MSG}}
                deletable={{type: 'inline', url: 'domain_types', msg: DELETE_MSG}}
            />
        </FetchContextProvider>
    );
}

