import {ListRecords} from "components/special/list_view"
import createGenericContext from "context/FetchContext";
import {FormControl, TextField} from "@mui/material";
import {Square} from "@mui/icons-material";
import {Column} from "@material-table/core";
import {StockState} from "../../../client";
import {rStockStates} from "../../../Requests/backend_routes";

const CREATE_MSG = "Etat de stock ajouté avec succès"
const UPDATE_MSG = "Etat de stock modifié avec succès"
const DELETE_MSG = "Etat de stock supprimé avec succès"

const {MyContext, FetchContextProvider} = createGenericContext<StockState>({getApi: rStockStates, defaultT: []})

export const StockStates = () => {

    const columns: Column<StockState>[] = [
        {title: "ID", field: "id", editable: 'never', hidden: true, width: '1%'},
        {title: 'Ordre', field: 'sequence', defaultSort: 'asc', width: '1%'},
        {title: "Nom", field: "name"},
        {
            title: "Coleur",
            field: "color",
            editComponent: (props) =>
                <FormControl fullWidth>
                    <TextField
                        type="color"
                        name="color"
                        value={props.value ? props.value : ''}
                        onChange={e => props.onChange(e.target.value)}
                    />
                </FormControl>,
            render: (rowData) => <Square sx={{...(rowData.color && {color: rowData.color})}}/>,
            width: "1%"
        }
    ]

    return (
        <FetchContextProvider>
            <ListRecords<StockState>
                title="Etats de Stock"
                ctx={MyContext}
                columns={columns}
                creatable={{type: 'inline', url: `${rStockStates.pathname}/new`, msg: CREATE_MSG}}
                updatable={{type: 'inline', url: rStockStates.pathname, msg: UPDATE_MSG}}
                deletable={{type: 'inline', url: rStockStates.pathname, msg: DELETE_MSG}}
            />
        </FetchContextProvider>
    );
}

