import React from "react";
import {ListRecords} from "components/special/list_view";
import {Cancel, CheckCircle, Launch, Square, Store} from "@mui/icons-material";
import createGenericContext from "context/FetchContext";
import {Column} from "@material-table/core";
import {StoreSimple} from "../../../client";
import {rStores} from "../../../Requests/backend_routes";
import {useNavigate} from "react-router";
import {groupRender, TableName} from "../../../tools/tools";
import {IMAGES_URL} from "../../../Requests/requests";
import {Box, MenuItem, Select} from "@mui/material";
import {Link} from "react-router-dom";

const DELETE_MSG = "Store supprimé";

type TStores = {
    defaultApi?: URL
    title?: string
    from?: "domain" | "user"
}

const rebuildMembership: (data: StoreSimple[]) => StoreSimple[] = (data: StoreSimple[]) => {
    const res: StoreSimple[] = []
    data.map((item) => res.push(
        {
            ...item,
            rebuilt_membership: item.membership !== null ? `${item.membership?.color}-${item.membership?.name}` : undefined
        }))
    return res
}

export const Stores = (props: TStores) => {

    const {MyContext, FetchContextProvider} = createGenericContext<StoreSimple>({getApi: props.defaultApi ?? rStores, defaultT: []})

    const navigate = useNavigate()
    const columns: Column<StoreSimple>[] = [
        {title: "ID", field: "id", hidden: true, editable: 'never', width: '1%'},
        {
            title: "Actif?",
            field: "active",
            groupRender: (groupKey) => groupRender(groupKey, <CheckCircle style={{color: "green"}}/>, <Cancel
                style={{color: "red"}}/>),
            render: (data) => data.active ? <CheckCircle htmlColor='green'/> : <Cancel htmlColor='red'/>,
            width: '5%',
            type: "boolean"
        },
        {
            field: "picture",
            title: "Image",
            render: (rowData) => {
                return (
                    <>
                        {rowData.picture && (
                            <img
                                src={`${IMAGES_URL}/${rowData.picture}`}
                                style={{width: 60, height: 40, objectFit: 'fill'}}
                            />
                        )}

                        {!rowData.picture && (
                            <Store
                                sx={{width: 60, height: 40, objectFit: 'fill'}}
                                htmlColor="grey"
                            />
                        )}
                    </>
                );
            },
            editable: "never",
            filtering: false,
            grouping: false,
            width: '1%'
        },
        {
            title: "Nom",
            field: "name",
            width: '25%',
            render: (rowData) => <TableName rowData={rowData} onClick={() => navigate(`/stores/${rowData.id}`)}/>
        },
        {
            title: "Propriétaire",
            field: "owner.full_name",
            width: '15%',
            editable: "never",
            render: (rowData) => (
                <>
                    {rowData.owner?.full_name}
                    <Link to={`/users/${rowData.owner?.id}`}>
                        <Launch/>
                    </Link>
                </>
            )
        },
        {
            title: "Domain",
            field: "domain.full_name",
            width: '35%',
            editable: "never",
            render: (rowData) => (
                <>
                    {rowData.domain?.full_name}
                    <Link to={`/users/${rowData.domain?.id}`} >
                        <Launch/>
                    </Link>
                </>
            ),
        },
        {
            title: "Abonn.",
            field: "rebuilt_membership",
            width: '10%',
            render: (rowData) => (
                rowData.membership == null ?
                    <span style={{display: 'inline-flex', verticalAlign: 'middle'}}></span>
                    :
                    <Box display='flex'>
                        <Square sx={{color: rowData.membership.color}}/> {rowData.membership.name}
                    </Box>
            ),
            groupRender: (groupKey) => {
                if (!groupKey.startsWith('undefined')) {
                    const [color, name] = groupKey.split('-');
                    return <span style={{display: 'inline-flex', verticalAlign: 'middle'}}><Square
                        sx={{color: color}}/> {name}</span>
                }
                return <span style={{display: 'inline-flex', verticalAlign: 'middle'}}><Cancel/></span>
            },
            filterComponent: (props) => {
                return <Select
                    onChange={(e) => {
                        const column: any = props.columnDef as any
                        props.onFilterChanged(column.tableData.id, e.target.value)
                    }}
                    fullWidth
                >
                    <MenuItem value=''><Cancel/></MenuItem>
                    <MenuItem value='None'>None</MenuItem>
                    <MenuItem value='Standard'>Standard</MenuItem>
                    <MenuItem value='Pro'>Pro</MenuItem>
                    <MenuItem value='Premium'>Premium</MenuItem>
                </Select>
            },
            customFilterAndSearch: (filter, rowData, columnDef) => {
                return filter === "None" ? rowData.membership === null : filter === rowData.membership?.name
            },
            editable: "never"
        },
        {
            title: "Créé le",
            field: "created_date",
            width: '10%',
            type: 'date',
            grouping: false,
            editable: "never"
        },
        {
            title: "Dernière Modif",
            field: "updated_date",
            width: '15%', type: 'datetime',
            filtering: false,
            grouping: false,
            hidden: true,
            editable: "never"
        },
    ];

    if (props.from) {
        props.from === "domain" ? columns.splice(5) : columns.splice(4)
    }

    return (
        <>
            <FetchContextProvider>
                <ListRecords<StoreSimple>
                    title={props.title ?? "Stores"}
                    limit={1000}
                    skip={0}
                    ctx={MyContext}
                    columns={columns}
                    options={{
                        pageSizeOptions: [100, 200, 500],
                        pageSize: 50,
                        grouping: true,
                        filtering: true,
                        actionsCellStyle: {width: 2}
                    }}
                    rebuildFunc={rebuildMembership}
                    creatable={{type: 'newWindow', url: '/stores/new'}}
                    deletable={{type: 'inline', url: rStores.pathname, msg: DELETE_MSG}}
                    updatable={{type: 'inline', url: rStores.pathname, msg: "Boutique modifiée avec succès"}}
                />
            </FetchContextProvider>
        </>
    );
};
