import {BASE_URL} from "./requests";

const path = BASE_URL.pathname

export const rLogin: URL = new URL(path + 'login/access-token', BASE_URL)
export const rTestToken: URL = new URL(path + 'login/test-token', BASE_URL)
export const rUsers: URL = new URL(path + 'users', BASE_URL)
export const rDomains: URL = new URL(path + 'domains', BASE_URL)
export const rDomainsContainers: URL = new URL(path + 'domains/containers', BASE_URL)
export const rCategories: URL = new URL(path + 'categories', BASE_URL)
export const rAttributes: URL = new URL(path + 'attributes', BASE_URL)
export const rAttributesValues: URL = new URL(path + 'attributes_values', BASE_URL)
export const rStores: URL = new URL(path + 'stores', BASE_URL)
export const rBids: URL = new URL(path + 'bids', BASE_URL)
export const rSystemNotifications: URL = new URL(path + 'system_notifications', BASE_URL)


export const rDomainTypes: URL = new URL(path + 'domain_types', BASE_URL)
export const rCountries: URL = new URL(path + 'countries', BASE_URL)
export const rAttributesGroup: URL = new URL(path + 'attributes_group', BASE_URL)
export const rColors: URL = new URL(path + 'colors', BASE_URL)
export const rPriceTypes: URL = new URL(path + 'price_types', BASE_URL)
export const rSaleTypes: URL = new URL(path + 'sale_types', BASE_URL)
export const rStockStates: URL = new URL(path + 'stock_states', BASE_URL)
export const rStoreTypes: URL = new URL(path + 'store_types', BASE_URL)
export const rMembershipPlan: URL = new URL(path + 'membership_plans', BASE_URL)
