import createGenericContext from "../../../context/FetchContext";
import {AdminUserOut, Category, MembershipFull, PublicStoreFull, StoreEmail, StorePhone} from "../../../client";
import {rUsers} from "../../../Requests/backend_routes";
import {BASE_URL, IMAGES_URL} from "../../../Requests/requests";
import React, {SyntheticEvent, useEffect, useState} from "react";
import {X2Many} from "../../special/x2many";
import {Column} from "@material-table/core";
import {nested_name_render} from "../../../tools/tools";
import {Avatar, Stack} from "@mui/material";
import {AppTab, AppTabs} from "../../overrides";
import {a11yProps, TabPanel} from "../../special/tab_panel";
import {ListRecords} from "../../special/list_view";
import {StoreMemberships} from "./store_memberships";
import {Reducer} from "../../../types";
import {Bids} from "../bids/bids";


type TStoreTabsController = { id: string, storeReducer: Reducer<PublicStoreFull> }

export function StoreTabsController({id, storeReducer}: TStoreTabsController): JSX.Element {

    const [tabValue, setTabValue] = useState<number>(0)
    const [domainId, setDomainId] = useState<number | undefined>(undefined)
    const [store, setStore] = useState<PublicStoreFull | undefined>(undefined)
    const handleTabChange = (event: SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    useEffect(() => {
        const unsubscribe = storeReducer.subscribe(
            (object) => {
                domainId === undefined && setDomainId(object?.domain?.id)
                store === undefined && setStore(object)
            }
        );
        return () => unsubscribe();
    }, [])

    if (id === "new") {
        return <></>
    }

    // One2Many store / emails
    const {
        MyContext: StoreEmailsContext,
        FetchContextProvider: StoreEmailsContextProvider
    } = createGenericContext<StoreEmail>(
        {
            getApi: new URL(`stores/${id}/emails`, BASE_URL),
            defaultT: []
        }
    )

    const storeEmailColumns: Column<StoreEmail>[] = [
        {title: "ID", field: "id", editable: 'never', hidden: true, width: '1%'},
        {title: "Email", field: "email"},
    ]

    // One2Many store / phones
    const {
        MyContext: StorePhonesContext,
        FetchContextProvider: StorePhonesContextProvider
    } = createGenericContext<StorePhone>(
        {
            getApi: new URL(`stores/${id}/phones`, BASE_URL),
            defaultT: []
        }
    )

    const storePhonesColumns: Column<StorePhone>[] = [
        {title: "ID", field: "id", editable: 'never', hidden: true, width: '1%'},
        {title: "Numéro", field: "number"},
    ]


    // Many2Many store / categories
    const {
        MyContext: CategoriesContext,
        FetchContextProvider: CategoriesContextProvider
    } = createGenericContext<Category>(
        {
            getApi: new URL(`domains/${domainId}/categories`, BASE_URL),
            defaultT: []
        }
    )

    const {
        MyContext: StoreCategoriesContext,
        FetchContextProvider: StoreCategoriesContextProvider
    } = createGenericContext<Category>(
        {
            getApi: new URL(`stores/${id}/allowed_categories`, BASE_URL),
            defaultT: []
        }
    )
    const categoriesColumns: Column<Category>[] = [

        {title: "ID", field: "id", hidden: true, width: '1%'},
        {title: 'Ordre', field: 'sequence', defaultSort: 'asc', width: '1%'},
        {
            title: "Nom",
            field: "name",
            width: '90%',
            render: (rowData) => nested_name_render(rowData)
        },
        {
            title: "Créé le",
            field: "created_date",
            type: "date",
            grouping: false,
            filtering: false,
            hidden: true,
            width: '15%'
        },
        {
            title: "Dernière modif",
            field: "updated_date",
            type: "date",
            grouping: false,
            filtering: false,
            hidden: true,
            width: '15%'
        }
    ];

    // Many2Many store / users
    const {
        MyContext: UsersContext,
        FetchContextProvider: UsersContextProvider
    } = createGenericContext<AdminUserOut>(
        {
            getApi: rUsers,
            defaultT: []
        }
    )

    const {
        MyContext: StoreUsersContext,
        FetchContextProvider: StoreUsersContextProvider
    } = createGenericContext<AdminUserOut>(
        {
            getApi: new URL(`subscriptions/${id}/subscribers`, BASE_URL),
            defaultT: []
        }
    )


    const usersColumns: Column<AdminUserOut>[] = [

        {
            title: "ID",
            field: "id",
            editable: "never",
            hidden: true
        },
        {
            title: "Nom",
            field: "full_name",
        },
        {
            field: "picture",
            title: "Image",
            render: (rowData) => {
                return (
                    <Stack direction="row" spacing={2}>
                        <Avatar
                            src={`${IMAGES_URL}/${rowData.picture}`}
                            sx={{width: 56, height: 56}}
                        />
                    </Stack>
                );
            },
        },
    ];

    // One2Many store / memberships
    const {
        MyContext: StoreMembershipContext,
        FetchContextProvider: StoreMembershipsContextProvider
    } = createGenericContext<MembershipFull>(
        {
            getApi: new URL(`memberships/${id}`, BASE_URL),
            defaultT: []
        }
    )

    return (
        <>
            {domainId && (
                <>
                    <AppTabs value={tabValue} onChange={handleTabChange}>
                        <AppTab label="Annonces" {...a11yProps(0)} />
                        <AppTab label="Emails" {...a11yProps(1)} />
                        <AppTab label="Téléphones" {...a11yProps(2)} />
                        <AppTab label="Abbonés" {...a11yProps(3)} />
                        <AppTab label="Abbonnements" {...a11yProps(4)} />
                        <AppTab label="Catégories" {...a11yProps(5)} />
                    </AppTabs>
                    <TabPanel value={tabValue} index={0}>
                        <Bids
                            title={`${store?.name} annonces`}
                            defaultApi={new URL(`stores/${store?.id}/bids`, BASE_URL)}
                            from="store"
                        />
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                        <StoreEmailsContextProvider>
                            <ListRecords
                                title='Emails'
                                ctx={StoreEmailsContext}
                                columns={storeEmailColumns}
                                deletable={{type: 'inline', url: 'stores/emails', msg: 'Email supprimé avec succès'}}
                                creatable={{
                                    type: 'inline',
                                    url: `stores/${id}/email/link`,
                                    msg: 'Email ajouté avec succès'
                                }}
                            />
                        </StoreEmailsContextProvider>
                    </TabPanel>
                    <TabPanel value={tabValue} index={2}>
                        <StorePhonesContextProvider>
                            <ListRecords
                                title='Téléphones'
                                ctx={StorePhonesContext}
                                columns={storePhonesColumns}
                                deletable={{
                                    type: 'inline',
                                    url: 'stores/phones',
                                    msg: 'Téléphone supprimé avec succès'
                                }}
                                creatable={{
                                    type: 'inline',
                                    url: `stores/${id}/phone/link`,
                                    msg: 'Téléphone ajouté avec succès'
                                }}
                            />
                        </StorePhonesContextProvider>
                    </TabPanel>
                    <TabPanel value={tabValue} index={3}>
                        <UsersContextProvider>
                            <StoreUsersContextProvider>
                                <X2Many
                                    title='Abonnés'
                                    dataCtx={UsersContext}
                                    selectedCtx={StoreUsersContext}
                                    saveApi={new URL(`subscriptions/${id}/link`, BASE_URL)}
                                    columns={usersColumns}
                                    successMsg='Utilisateurs assignées avec succès'
                                    options={{selection: true, pageSize: 50, pageSizeOptions: [50, 100, 200]}}
                                />
                            </StoreUsersContextProvider>
                        </UsersContextProvider>
                    </TabPanel>
                    <TabPanel value={tabValue} index={4}>
                        <StoreMembershipsContextProvider>
                            <StoreMemberships storeId={id} ctx={StoreMembershipContext}/>
                        </StoreMembershipsContextProvider>
                    </TabPanel>
                    <TabPanel value={tabValue} index={5}>
                        <CategoriesContextProvider>
                            <StoreCategoriesContextProvider>
                                <X2Many
                                    title='Categories'
                                    dataCtx={CategoriesContext}
                                    selectedCtx={StoreCategoriesContext}
                                    saveApi={new URL(`stores/${id}/categories/link`, BASE_URL)}
                                    columns={categoriesColumns}
                                    successMsg='Catégories assignées avec succès'
                                    parentChildData={(row, rows) =>
                                        rows.find((a) => a.id === row.parent_id)
                                    }
                                    options={{selection: true, pageSize: 50, pageSizeOptions: [50, 100, 200]}}
                                />
                            </StoreCategoriesContextProvider>
                        </CategoriesContextProvider>
                    </TabPanel>
                </>
            )}
        </>
    )
}
