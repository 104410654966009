import React, {SyntheticEvent, useEffect, useState} from "react";
import {Autocomplete, Input, TextField} from "@mui/material";
import {WidgetMany2OneProps} from "../../../types";
import {getData} from "../../../context/FetchContext";
import {useFormContext} from "../../../context/FormContext";
import {FieldRenderer} from "../FieldRenderer";
import Typography from "@mui/material/Typography";
import {unpack_nested} from "../../../tools/tools";
import {Launch} from "@mui/icons-material";
import {Link} from "react-router-dom";


export function Many2OneWidget<T extends { [key: string]: any }, K extends { [key: string]: any }>
(
    {
        item,
        defaultSelected,
        filterFunc,
        externalLink,
        nested
    }: WidgetMany2OneProps<T, K>
) {

    const [list, setList] = useState<K[]>([]);
    const [open, setOpen] = useState<boolean>(false)
    const [value, setValue] = useState<number | "">(defaultSelected ? defaultSelected['id'] : "")
    const loading = open && list.length === 0

    const {mode} = useFormContext()

    const required = mode === "view" ? false : item[mode] === 'required'
    const readonly = mode === "view" ? true : item[mode] === 'readonly'
    const hidden = mode === "view" ? item.hidden : item[mode] === 'hidden'

    useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        (
            async () => await getData<T>(item.relationalAPI, 0, 1000, setList)
        )()

        return () => {
            active = false;
        };
    }, [loading]);

    useEffect(() => {

        let unpacked: K[] = []
        if (nested && list.length > 0) unpack_nested(list, unpacked)
        if (!nested) unpacked = list

        if (filterFunc && list.length > 0) unpacked = unpacked.filter((r) => filterFunc(r))

        setList(unpacked)

    }, [loading]);

    function handleChange(e: SyntheticEvent, inputValue: any) {
        item.onChange && item.onChange(e, inputValue)
        inputValue ? setValue(inputValue['id']) : setValue("")
    }

    if (!hidden) {
        return (
            <FieldRenderer
                label={
                    required ? <>
                        {item.label}
                        <Typography
                            component='span'
                            color='primary'>
                            *
                        </Typography>
                    </> : item.label as string
                }
                text={defaultSelected ? defaultSelected[item.externalLabelKey] : ""}
                editComponent={
                    <>
                        <Input
                            value={value}
                            type='number'
                            name={item.field.toString()}
                            sx={{display: 'none'}}
                        />
                        <Autocomplete
                            options={list}
                            onOpen={() => setOpen(true)}
                            onClose={() => {
                                setOpen(false);
                            }}
                            open={open}
                            defaultValue={defaultSelected}
                            getOptionLabel={(option) => option[item.externalLabelKey] as string || ''}
                            onChange={handleChange}
                            renderInput={(params) =>
                                <TextField
                                    required={required}
                                    variant='standard'
                                    placeholder={item.placeHolder}
                                    {...params}
                                />}
                            clearOnEscape
                            disabled={readonly}
                            fullWidth
                        />
                    </>
                }
                extra={
                    value !== "" && externalLink ? (
                        <Link to={`${externalLink}/${value}`}>
                            <Launch/>
                        </Link>) : undefined
                }
                readonly={readonly}
            />

        );
    }
    return <></>

}
