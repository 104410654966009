import {useParams} from "react-router-dom";
import createGenericContext from "../../../context/FetchContext";
import {SystemNotification} from "../../../client";
import {rSystemNotifications} from "../../../Requests/backend_routes";
import {BASE_URL, PostOptions} from "../../../Requests/requests";
import React, {SyntheticEvent, useContext} from "react";
import {TActionsProps, TFormField} from "../../../types";
import {AdminFormSample} from "../../form/AdminFormSample";
import {FormContextProvider} from "../../../context/FormContext";
import {useNavigate} from "react-router";
import {Button} from "@mui/material";


function Actions(props: TActionsProps<SystemNotification>): JSX.Element {

    const {fetchForCUD} = useContext(props.ctx);

    const handleSend = async () => {
        const API = new URL(`system_notifications/${props.id}/send`, BASE_URL);
        try {
            await fetchForCUD(API, PostOptions(), 'Notification envoyée')
        } catch (error) {

        }
    }

    return (
        <>
            {!props.data.sent_date && (
                <Button onClick={handleSend} variant='contained' color='success'> Envoyer </Button>
            )}
        </>
    )
}

export function SystemNotificationController(): JSX.Element {

    const {id} = useParams();
    const navigate = useNavigate()

    const {
        MyContext,
        FetchContextProvider
    } = createGenericContext<SystemNotification>(
        {
            getApi: new URL(`${rSystemNotifications.pathname}/${id}`, BASE_URL),
            defaultT: {}
        }
    )

    const fields: TFormField<SystemNotification>[] = [
        {
            field: "title",
            label: 'Titre',
            placeHolder: "Titre de notification",
            create: "required",
            update: "optional",
            position: 'Left',
            order: 1,
            type: "text"
        },
        {
            field: "body",
            label: "Corp",
            placeHolder: "Corp de notification",
            create: "required",
            update: "optional",
            position: 'Left',
            order: 2,
            type: "text"
        },
        {
            field: "object_type",
            label: "System Objet",
            placeHolder: "Objet utilisé pour la redirection",
            create: "required",
            update: "readonly",
            position: 'Left',
            order: 3,
            type: "text"
        },
        {
            field: "object_type_id",
            label: "ID de l'objet",
            placeHolder: "ID représentant l'item de la redirection",
            create: "required",
            update: "readonly",
            position: 'Left',
            order: 4,
            type: "text"
        },
        {
            field: "sent_date",
            label: "Envoyée le",
            create: "hidden",
            update: "readonly",
            position: 'Header',
            order: 1,
            type: "date"
        },
    ]

    async function afterDone(res: any, e: SyntheticEvent<HTMLFormElement, SubmitEvent>) {
        const uid = parseInt(res)
        if (!isNaN(uid)) navigate(`/system_notifications/${uid}`)
    }

    return (
        <>
            <FetchContextProvider>
                <FormContextProvider startMode={id === "new" ? 'create' : undefined}>
                    <AdminFormSample<SystemNotification>
                        id={id as string}
                        baseApi={rSystemNotifications}
                        renderFields={fields}
                        ctx={MyContext}
                        successCreateMsg="Notification ajoutée avec succès"
                        successUpdateMsg="Notification modifiée avec succès"
                        callBack={afterDone}
                        callBackCancel={() => navigate('/system_notifications')}
                        ActionsComponent={Actions}
                    />
                </FormContextProvider>
            </FetchContextProvider>
        </>
    )
}
