import {Box} from "@mui/material";
import {TAnyData} from "../types";
import {RequestPictureCommon} from "../client";
import ListItemButton from "@mui/material/ListItemButton";
//import { isArray, isEmpty } from "lodash";

export const getDifference = (a: Record<string, any>, b: Record<string, any>) =>
    Object.fromEntries(
        Object.entries(b).filter(
            ([key, val]) => (key in a
                    && a[key] !== val) ||
                (key === "attribute_values") || (key === "new_custom_attributes") || (key === "custom_attributes") || (key === "password")
        )
    );

export const convertBase64 = async (file: File) => {

    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
            resolve(fileReader.result);
        };
        fileReader.onerror = (error) => {
            reject(error);
        };
    });
};


export const imageUriToObject = (uri: string): RequestPictureCommon => {
    let splited = uri.split(',')
    const img_data = splited[1]
    const mimetype = splited[0].split(';')[0].split(':')[1]
    return {
        picture_bin: img_data,
        picture_mimetype: mimetype
    }
}

export const unpack_nested = <T extends Record<string, any>, >(data: T[], array_result: T[]) => {
    data.map((item, index) => {
        if (item.children) {
            array_result.push(item)
            unpack_nested(item.children, array_result)
        } else {
            array_result.push(item)
        }
    })
}

export const nested_name_render = (rowData: any) => {
    if (rowData.tableData.path.length === 1) {
        return rowData.name
    }
    return (
        <>
            <Box sx={{padding: 0, margin: 0, width: '100%', position: 'relative'}} flexDirection='row' display='flex'>
                {
                    rowData.tableData.path.map(
                        (path: any, index: number) => index > 0 && index - 1 > 0 ? (
                            <span key={index}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                        ) : <span></span>
                    )}
                {/*<MagicBox />*/}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Box sx={{padding: 0, margin: 0}}>{rowData.name}</Box>
            </Box>
        </>
    )
}


export const groupRender:
    (groupKey: string, trueRender: JSX.Element, falseRender: JSX.Element) => JSX.Element
    = (groupKey: string, trueRender: JSX.Element, falseRender: JSX.Element) => {
    const Render = ({bool}: { bool: boolean }) => bool ? trueRender : falseRender
    var count = "";
    var splited: string[] = groupKey.split(' ')
    if (splited.length > 1) {
        count = splited[1]
    }
    return <> <Render bool={splited[0] === 'true'}/> {count}</>
}

export function buildPicture(obj: TAnyData) {

    if ('picture' in obj) {
        const _local_pic: RequestPictureCommon = {picture_bin: null, picture_mimetype: null}
        if (obj['picture'] !== "" && obj['picture'] !== null) {
            // convert
            const _pic_obj: RequestPictureCommon = imageUriToObject(obj['picture'])
            _local_pic.picture_bin = _pic_obj.picture_bin
            _local_pic.picture_mimetype = _pic_obj.picture_mimetype
        }
        delete obj['picture']
        obj['picture_bin'] = _local_pic.picture_bin
        obj['picture_mimetype'] = _local_pic.picture_mimetype
    }
}


export function TableName<T extends {[K: string]: any, name: string}>(props: { rowData: T, onClick: () => void }): JSX.Element {

    return (
        <ListItemButton
            sx={{position: 'absolute', width: '100%', height: '100%', top:0}}
            onClick={props.onClick}
        >
            {props.rowData.name}
        </ListItemButton>
    )
}

