import React, {useEffect, useState} from "react";
import {
    Alert,
    AlertColor,
    Box,
    Button,
    CircularProgress,
    Container,
    CssBaseline,
    Snackbar,
    TextField
} from "@mui/material";
import {useNavigate} from "react-router";
import useAuth from "hooks/useAuth";
import {rLogin} from "../../Requests/backend_routes";
import {FormOptions} from "../../Requests/requests";
import {TLoginResponse, TResponse} from "../../types";
import Typography from "@mui/material/Typography";

function Copyright(props: any) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            Copyright © DZKingdom {new Date().getFullYear()}
        </Typography>
    );
}

const Login: React.FC = () => {
    const {user, setUser} = useAuth();

    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [responseText, setResponseText] = useState("");
    const [snackOpen, setSnackOpen] = useState(false);
    const [responseType, setResponseType] = useState<AlertColor>("info");

    useEffect(() => {
        if (user) {
            navigate('/', {replace: true, relative: "path"})
        }
    }, [user])

    const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e: React.FormEvent<HTMLFormElement>) => {
        setIsLoading(true);
        const target = e.target as EventTarget & { username: any, passwd: any }
        e.preventDefault();
        FormOptions.body = new URLSearchParams({
            username: target.username.value,
            password: target.passwd.value,

        });
        const res: Awaited<Response> = await fetch(rLogin, FormOptions);
        setIsLoading(false);
        switch (res.status) {
            case 200: {
                const data: Awaited<TLoginResponse> = await res.json();
                localStorage.setItem('token', data.access_token);
                setUser(true)
                setResponseText("Succès");
                setResponseType("success");
                setSnackOpen(true);
                break;
            }
            case 400:
            case 401: {
                const err: Awaited<TResponse> = await res.json();
                setSnackOpen(true);
                setResponseText(err.detail as string);
                setResponseType("error");
                break;
            }
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <img src="logo.png" alt="DZKingdom" width="50%"/>
                <Box component="form" onSubmit={handleSubmit} sx={{mt: 1}}>
                    <TextField
                        margin="normal"
                        type="text"
                        label="Username"
                        name="username"
                        fullWidth
                        autoFocus
                    />

                    <TextField
                        margin="normal"
                        label="Password"
                        type="password"
                        name="passwd"
                        fullWidth
                    />
                    {!isLoading && (
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            sx={{mt: 3, mb: 2}}
                            fullWidth
                        >
                            Login
                        </Button>
                    )}
                    {isLoading && <CircularProgress/>}
                </Box>
                <Snackbar
                    anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                    open={snackOpen}
                    autoHideDuration={6000}
                    onClose={(e) => setSnackOpen(false)}
                >
                    <Alert
                        onClose={(e) => setSnackOpen(false)}
                        severity={responseType}
                        sx={{width: "100%"}}
                    >
                        {responseText}
                    </Alert>
                </Snackbar>
            </Box>
            <Copyright sx={{mt: 8, mb: 4}}/>
        </Container>
    );
};

export default Login;
