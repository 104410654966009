import {Context, createContext, PropsWithChildren, useContext, useState} from "react";
import {TFormContext, TFormContextMode} from "../types";


const FormContext: Context<TFormContext> = createContext<TFormContext>({} as TFormContext)

export function FormContextProvider ({children, startMode}: PropsWithChildren<{startMode?: TFormContextMode}>): JSX.Element {

    const [mode, setMode] = useState<TFormContextMode>(startMode ? startMode : 'view')

    return (
        <FormContext.Provider
            value={{mode, setMode}}
        >
            {children}
        </FormContext.Provider>
    )
}


export function useFormContext (): TFormContext {
    return useContext(FormContext)
}
