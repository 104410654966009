import Typography from "@mui/material/Typography";
import React from "react";
import Grid from "@mui/material/Grid";

type TRendererField = {
    label: string | JSX.Element
    text?: string
    readonly: boolean
    editComponent: JSX.Element
    extra?: JSX.Element
}

export function FieldRenderer({label, text, readonly, editComponent, extra}: TRendererField) {

    return (
        <div style={{display: 'flex', flexDirection: 'row', margin: '10px 0'}}>
            <Grid flexBasis='45%'>
                <Typography sx={{fontWeight: 'bold'}}>
                    {label}
                </Typography>
            </Grid>

            <Grid sx={{width: '100%'}} display='flex' >
                {readonly && (
                    <>
                        <Typography sx={{fontWeight: 'normal'}}>
                            {text}
                        </Typography>
                    </>
                )}
                {!readonly && editComponent}
                {extra}
            </Grid>
        </div>
    )

}
