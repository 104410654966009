import createGenericContext from "../../../context/FetchContext";
import {AttributeFull, AttributeValueWithParent} from "../../../client";
import {BASE_URL, IMAGES_URL} from "../../../Requests/requests";
import React, {SyntheticEvent, useEffect, useState} from "react";
import {Column} from "@material-table/core";
import {AppTab, AppTabs} from "../../overrides";
import {a11yProps, TabPanel} from "../../special/tab_panel";
import {Image} from "@mui/icons-material";
import {useNavigate} from "react-router";
import {Reducer} from "../../../types";
import {ListRecords} from "../../special/list_view";
import {attributesValuesFields} from "../attributes_values/attribute_value_controller";
import {rAttributesValues} from "../../../Requests/backend_routes";

export function AttributeTabsController({id, catId, reducer}: { id: string, catId: string, reducer: Reducer<AttributeFull> }): JSX.Element {

    const navigate = useNavigate()
    const [attribute, setAttribute] = useState<AttributeFull | undefined>(undefined)

    useEffect(() => {
        const unsubscribe = reducer.subscribe((object) => attribute === undefined && setAttribute(object));
        return () => unsubscribe();
    }, [])

    const [tabValue, setTabValue] = useState<number>(0)
    const handleTabChange = (event: SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    if (id === "new") return <></>

    // One2Many attributes / values
    const {
        MyContext: AttributeValuesContext,
        FetchContextProvider: AttributeValuesContextProvider
    } = createGenericContext<AttributeValueWithParent>(
        {
            getApi: new URL(`attributes/${id}/values`, BASE_URL),
            defaultT: []
        }
    )

    const attributeValuesColumns: Column<AttributeValueWithParent>[] = [
        {
            title: "ID",
            field: "id",
            hidden: true
        },
        {
            title: "Ordre",
            field: "sequence",
            defaultSort: 'asc'
        },
        {
            title: "Nom",
            field: "name",
        }

    ];

    if (attribute?.display_type === "OneManyAvatar") attributeValuesColumns.push({
        field: 'picture',
        width: "10%",
        editable: "never",
        render: (rowData) => {
            return (
                <>
                    {rowData.picture && (
                        <img
                            src={`${IMAGES_URL}/${rowData.picture}`}
                            style={{height: 100, objectFit: 'fill'}}
                        />
                    )}

                    {!rowData.picture && (
                        <Image sx={{
                            width: '100%',
                            height: "150px",
                            backgroundColor: '#bdbdbd',
                            color: "white",
                            objectFit: "fill",
                        }}
                        />
                    )}
                </>
            );
        }
    })

    return (
        <>
            {attribute?.is_custom === false && (
                <>
                    <AppTabs value={tabValue} onChange={handleTabChange}>
                        <AppTab label="Valeurs" {...a11yProps(0)} />
                    </AppTabs>

                    <TabPanel value={tabValue} index={0}>
                        <AttributeValuesContextProvider>
                            <ListRecords<AttributeValueWithParent>
                                title='Valeurs'
                                ctx={AttributeValuesContext}
                                columns={attributeValuesColumns}
                                creatable={{
                                    type: 'popup',
                                    formProps: {
                                        id: 'new',
                                        renderFields: attributesValuesFields(catId),
                                        fixedFields: [{field: 'attribute_id', value: id}],
                                        ctx: AttributeValuesContext,
                                        successCreateMsg: 'Valeur ajoutée avec succès',
                                        successUpdateMsg: 'Valeur modifiée avec succès',
                                        createApi: new URL(`attributes_values/new`, BASE_URL),
                                        addAndEdit: true
                                    }
                                }}
                                keepAfterEdit
                                saveAndNewCallback={(res, e) => navigate(`/categories/${catId}/attributes/${id}/values/${res}`)}
                                deletable={{
                                    type: 'inline',
                                    url: 'attributes_values',
                                    msg: 'Attribut supprimée avec succès'
                                }}
                                updatable={{type: 'inline', url: rAttributesValues.pathname, msg: "Valeur modifiée avec succès"}}
                                onRowClick={(event, rowData) => navigate(`/categories/${catId}/attributes/${id}/values/${rowData?.id}`)}
                                options={{pageSizeOptions: [100,200,500], pageSize: 100}}
                            />
                        </AttributeValuesContextProvider>
                    </TabPanel>
                </>
            )}
        </>
    )
}
