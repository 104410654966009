import {ListRecords} from "components/special/list_view"
import {Column} from "@material-table/core";
import {AttributeGroup} from "../../../client";
import {rAttributesGroup, rCategories} from "../../../Requests/backend_routes";
import createGenericContext from "../../../context/FetchContext";
import {TFormField} from "../../../types";
import {BASE_URL} from "../../../Requests/requests";


const DELETE_MSG = "Group supprimé avec succès"

const {MyContext, FetchContextProvider} = createGenericContext<AttributeGroup>({getApi: rAttributesGroup, defaultT: []})

const attributeGroupFields: TFormField<AttributeGroup>[] = [
    {
        field: "name",
        label: "Nom",
        placeHolder: "Nom de group",
        type: "text",
        order: 1,
        position: "Left",
        create: "required",
        update: "optional"
    },
    {
        field: "category_id",
        label: "Catégorie",
        placeHolder: "Catégorie du group",
        order: 2,
        position: "Left",
        create: "required",
        update: 'optional',
        type: "many2one",
        localObject: "category",
        localValue: "full_name",
        relationalAPI: rCategories,
        externalLabelKey: 'full_name'
    },
    {
        field: "values_separator",
        label: "Séparateur",
        placeHolder: "Séparateur des valeurs aossicier avec ce group",
        type: "text",
        order: 3,
        position: "Left",
        create: "optional",
        update: "optional"
    },


]
export const AttributesGroups = () => {
    const columns: Column<AttributeGroup>[] = [
        {
            title: "ID",
            field: "id",
            editable: 'never',
            hidden: true
        },
        {
            title: "Nom",
            field: "name",
        },
        {
            title: "Séprateur des valeurs",
            field: "values_separator",
        },
        {
            title: "Categorie",
            field: "category.full_name",
            defaultGroupOrder: 1,
            editable: 'never'
        }
    ];

    return (
        <>
            <FetchContextProvider>
                <ListRecords<AttributeGroup>
                    skip={0}
                    limit={1000}
                    title="Groups des attributs"
                    ctx={MyContext}
                    columns={columns}
                    options={{grouping: true, pageSizeOptions: [100, 200, 500], pageSize: 100}}
                    creatable={
                        {
                            type: 'popup',
                            formProps: {
                                id: "new",
                                ctx: MyContext,
                                renderFields: attributeGroupFields,
                                successCreateMsg: 'Group ajouté avec succès',
                                successUpdateMsg: 'Group modifié avec succès',
                                createApi: new URL(`${rAttributesGroup.pathname}/new`,BASE_URL)
                            },
                        }
                    }
                    updatable={{type: 'inline', url: rAttributesGroup.pathname, msg: 'Group modifié avec succès'}}
                    deletable={{type: 'inline', url: rAttributesGroup.pathname, msg: DELETE_MSG}}
                />
            </FetchContextProvider>
        </>
    );
}
