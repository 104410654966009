import {useParams} from "react-router-dom";
import createGenericContext from "../../../context/FetchContext";
import {DomainFull} from "../../../client";
import {rCountries, rDomains, rDomainTypes} from "../../../Requests/backend_routes";
import {BASE_URL} from "../../../Requests/requests";
import React, {SyntheticEvent} from "react";
import {DomainTabsController} from "./domain_tabs_controller";
import {AdminFormSample} from "../../form/AdminFormSample";
import {FormContextProvider} from "../../../context/FormContext";
import {TFormField} from "../../../types";
import {useNavigate} from "react-router";
import {formStore} from "../../../formStore";

let domainReducer = formStore<DomainFull>()

export function DomainController(): JSX.Element {

    const {id} = useParams();
    const navigate = useNavigate()

    const {
        MyContext,
        FetchContextProvider
    } = createGenericContext<DomainFull>(
        {
            getApi: new URL(`${rDomains.pathname}/${id}`, BASE_URL),
            defaultT: {}
        }
    )

    const fields: TFormField<DomainFull>[] = [
        {
            field: "stores_on",
            label: 'Boutiques',
            create: "optional",
            update: "optional",
            position: 'Header',
            order: 1,
            type: "boolean"
        },
        {
            field: "active",
            label: 'Actif',
            create: "optional",
            update: "optional",
            position: 'Header',
            order: 2,
            type: "boolean"
        },
        {
            field: "full_name",
            label: 'Nom Complet',
            placeHolder: "Nom complet",
            create: "hidden",
            update: "readonly",
            position: 'Left',
            order: 2,
            type: "text"
        },
        {
            field: "name",
            label: 'Nom',
            placeHolder: "Nom",
            create: "required",
            update: "optional",
            position: 'Left',
            order: 3,
            type: "text"
        },
        {
            field: "sequence",
            label: 'Ordre',
            placeHolder: "Sequence",
            create: "optional",
            update: "optional",
            position: 'Left',
            order: 6,
            type: "number"
        },
        {
            field: "parent_id",
            label: "Domain parent",
            placeHolder: "Choisir le domain parent",
            create: "optional",
            update: "optional",
            position: 'Left',
            order: 4,
            type: "many2one",
            relationalAPI: rDomains,
            localObject: "parent",
            localValue: "full_name",
            externalLabelKey: "full_name"

        },
        {
            field: "d_type",
            label: "Type de domain",
            placeHolder: "Spécifier le type de domain",
            create: "required",
            update: "readonly",
            position: 'Left',
            order: 5,
            type: "many2one",
            relationalAPI: rDomainTypes,
            localObject: "type",
            localValue: "name",
            externalLabelKey: "name"

        },
        {
            field: "country_id",
            label: "Pays",
            placeHolder: "Spécifier le pays de domain",
            create: "required",
            update: "readonly",
            position: 'Left',
            order: 1,
            type: "many2one",
            relationalAPI: rCountries,
            localObject: "country",
            localValue: "name",
            externalLabelKey: "name"

        },
        {
            field: "latitude",
            label: "Latitude",
            placeHolder: "Latitude de domain utilisée pour le GPS",
            create: "optional",
            update: "optional",
            position: 'Left',
            order: 7,
            type: "float",
            step: "0.000001"
        },
        {
            field: "longitude",
            label: "Longitude",
            placeHolder: "Longtitude de domain utilisée pour le GPS",
            create: "optional",
            update: "optional",
            position: 'Left',
            order: 8,
            type: "float",
            step: "0.000001"
        },
        {
            field: "description",
            label: "Description",
            placeHolder: "Informations sur le domain",
            create: "optional",
            update: "optional",
            position: 'Left',
            order: 9,
            type: 'long_text'
        },
        {
            field: "picture",
            create: "optional",
            update: "optional",
            position: 'Right',
            order: 1,
            type: "picture"
        },
        {
            field: "pictures",
            create: "optional",
            update: "optional",
            position: 'Right',
            order: 4,
            type: "pictures",
            maxItems: 5
        }
    ]

    async function afterDone(res: any, e: SyntheticEvent<HTMLFormElement, SubmitEvent>) {

        const uid = parseInt(res)
        if(!isNaN(uid) && id === "new") navigate(`/domains/${uid}`)

    }

    return (
        <>
            <FetchContextProvider>
                <FormContextProvider startMode={id === "new" ? 'create' : undefined}>
                    <AdminFormSample<DomainFull>
                        id={id as string}
                        ctx={MyContext}
                        baseApi={rDomains}
                        renderFields={fields}
                        successCreateMsg="Domain ajouté avec succès"
                        successUpdateMsg="Domain modifié avec succès"
                        mainPage="/domains"
                        reducer={domainReducer}
                        callBack={afterDone}
                    >
                    </AdminFormSample>
                </FormContextProvider>
            </FetchContextProvider>
            {id !== "new" && (
                <DomainTabsController id={id as string} domainReducer={domainReducer}/>
            )}
        </>
    )
}
