import React from "react";
import {useParams} from "react-router-dom";
import createGenericContext from "../../../context/FetchContext";
import {Category} from "../../../client";
import {rCategories} from "../../../Requests/backend_routes";
import {BASE_URL} from "../../../Requests/requests";
import {AdminFormSample} from "../../form/AdminFormSample";
import {FormContextProvider} from "../../../context/FormContext";
import {CategoryTabsController} from "./category_tabs_controller";
import {categoryFormFields} from "./categories";

export function CategoryController(): JSX.Element {

    const {id} = useParams();
    const {
        MyContext,
        FetchContextProvider
    } = createGenericContext<Category>(
        {
            getApi: new URL(`${rCategories.pathname}/${id}`, BASE_URL),
            defaultT: undefined
        }
    )

    return (
        <>
            <FetchContextProvider>
                <FormContextProvider startMode={id === "new" ? 'create' : undefined}>
                    <AdminFormSample<Category>
                        id={id as string}
                        baseApi={rCategories}
                        renderFields={categoryFormFields(id as string)}
                        ctx={MyContext}
                        successCreateMsg="Category ajoutée avec succès"
                        successUpdateMsg="Category modifiée avec succès"
                    >
                    </AdminFormSample>
                </FormContextProvider>
            </FetchContextProvider>
            {id !== "new" && (
                <CategoryTabsController catId={id as string} />
            )}
        </>
    )
}
