import {Button, Stack} from "@mui/material"
import {useFormContext} from "../../context/FormContext";


type TSavingButtons = {
    onCancel: () => void
    addAndEdit?: boolean
    isPopUp?: boolean
}
export const SavingButtons = ({onCancel, addAndEdit, isPopUp}: TSavingButtons) => {

    const {mode, setMode} = useFormContext()

    return (
        <Stack spacing={2} direction="row">

            {mode === "update" && !isPopUp && (
                <Button
                    variant="outlined"
                    type="reset"
                    name="cancel"
                    onClick={() => setMode('view')}
                    sx={{textTransform: 'none'}}
                >
                    Annuler
                </Button>
            )}
            {(mode === "create" || mode === "update") && (
                <>
                    <Button
                        variant="contained"
                        type="submit"
                        name="save"
                        sx={{textTransform: 'none'}}
                    >
                        Sauvegarder
                    </Button>
                    {addAndEdit && (
                        <Button
                            variant="contained"
                            type="submit"
                            name="save_and_edit"
                            sx={{textTransform: 'none'}}
                        >
                            Sauvegarder et modifier
                        </Button>
                    )}
                </>
            )}
            {mode === "view" && (
                <Button
                    variant="contained"
                    type="button"
                    name="edit"
                    onClick={() => setMode('update')}
                    sx={{textTransform: 'none'}}
                >
                    Modifier
                </Button>
            )}
            {((mode !== "update" && !isPopUp) || isPopUp) && (
                <Button
                    variant="outlined"
                    onClick={onCancel}
                    sx={{textTransform: 'none'}}
                >
                    Retour
                </Button>
            )}


        </Stack>
    )
}
