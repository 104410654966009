import React, {PropsWithChildren, useContext, useEffect, useLayoutEffect} from "react";
import {TFetchContext, TFormProps} from "../../types";
import {Alert, CircularProgress} from "@mui/material";
import {AdminForm} from "./AdminForm";


export function AdminFormSample<GetM extends { [key: string]: any }>(props: PropsWithChildren<Omit<TFormProps<GetM>, "data">>): JSX.Element {

    const {useGetData} = useContext<TFetchContext<GetM>>(props.ctx)

    const {response, loading, err} = useGetData(props.id === "new")

    useLayoutEffect(() => {
        document.documentElement.scrollTo(0, 0);
    }, [response]);

    useEffect(() => {
        if (response) {
            props.reducer && props.reducer.setObject(response)
        }
    }, [response])

    if (loading || (response === undefined && props.id !== "new")) {
        return <CircularProgress />
    }

    if (err)
        return (
            <Alert severity="error" sx={{width: "100%"}}>
                Erreur est survenue !
            </Alert>
        );

    return (
        <AdminForm {...props} data={response}/>
    )
}
