import {ListRecords} from "components/special/list_view"
import createGenericContext from "context/FetchContext";
import {Column} from "@material-table/core";
import {Country} from "../../../client";
import {rCountries} from "../../../Requests/backend_routes";

const CREATE_MSG = "Pays ajouté avec succès"
const UPDATE_MSG = "Pays modifié avec succès"
const DELETE_MSG = "Pays supprimé avec succès"

const {MyContext, FetchContextProvider} = createGenericContext<Country>({getApi: rCountries, defaultT: []})

export function Countries (): JSX.Element {

    const columns: Column<Country>[] = [
        {title: "ID", field: "id", editable: 'never', hidden: true},
        {title: "Pays", field: "name"}
    ];

    return (
        <FetchContextProvider>
            <ListRecords<Country>
                title="Pays"
                ctx={MyContext}
                columns={columns}
                creatable={{type: 'inline', url: 'countries/new', msg: CREATE_MSG}}
                updatable={{type: 'inline', url: 'countries', msg: UPDATE_MSG}}
                deletable={{type: 'inline', url: 'countries', msg: DELETE_MSG}}
            />
        </FetchContextProvider>
    );
}

