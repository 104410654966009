import AuthContext from "context/AuthContext";
import {useContext} from "react";
import {TAuthContext} from "../types";


const useAuth: () => TAuthContext = () => {
    return useContext(AuthContext);
}

export default useAuth;
