import React from "react";
import {TextInputProps} from "../../../types";
import {FormControl, TextField} from "@mui/material";
import {useFormContext} from "../../../context/FormContext";
import {FieldRenderer} from "../FieldRenderer";
import Typography from "@mui/material/Typography";


export function TextInput<T>({item, type, step, defaultValue, ...props}: TextInputProps<T>): JSX.Element {

    const {mode} = useFormContext()

    const hidden = mode === "view" ? item.hidden : item[mode] === 'hidden'
    const required = mode === "view" ? false : item[mode] === 'required'
    const readonly = mode === "view" ? true : item[mode] === 'readonly'


    if (!hidden) {
        return (
            <FieldRenderer
                label={required ? <> {item.label} <Typography component='span'
                                                              color='primary'>*</Typography></> : item.label as string}
                text={defaultValue as string}
                editComponent={
                    <FormControl fullWidth sx={{position: 'relative'}}>
                        <TextField
                            id={`id-${item.field.toString()}`}
                            name={item.field.toString()}
                            placeholder={item.placeHolder}
                            required={required}
                            disabled={readonly}
                            type={type}
                            defaultValue={defaultValue}
                            inputProps={{step: step ? step : "1"}}
                            variant='standard'
                            {...props}
                        />
                    </FormControl>
                }
                readonly={readonly}
            />

        )
    }
    return <></>

}
