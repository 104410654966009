import createGenericContext from "../../../context/FetchContext";
import {AttributeFull} from "../../../client";
import {BASE_URL} from "../../../Requests/requests";
import React, {SyntheticEvent, useState} from "react";
import {Column} from "@material-table/core";
import {groupRender} from "../../../tools/tools";
import {AppTab, AppTabs} from "../../overrides";
import {a11yProps, TabPanel} from "../../special/tab_panel";
import {Cancel, CheckCircle} from "@mui/icons-material";
import {ListRecords} from "../../special/list_view";
import {attributesFields} from "../attributes/attribute_controller";
import {useNavigate} from "react-router";
import {rAttributes} from "../../../Requests/backend_routes";

export function CategoryTabsController({catId}: { catId: string }): JSX.Element {

    const navigate = useNavigate()
    const [tabValue, setTabValue] = useState<number>(0)
    const handleTabChange = (event: SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    if (catId === "new") return <></>

    // One2Many category / attributes
    const {
        MyContext: AttributesContext,
        FetchContextProvider: AttributesContextProvider
    } = createGenericContext<AttributeFull>(
        {
            getApi: new URL(`categories/${catId}/attributes`, BASE_URL),
            defaultT: []
        }
    )

    const attributeColumns: Column<AttributeFull>[] = [
        {
            title: "ID",
            field: "id",
            hidden: true,
            editable: "never"
        },
        {
            title: "Ordre",
            field: "sequence",
            defaultSort: 'asc'
        },
        {
            title: "Nom",
            field: "name",
        },
        {
            title: "Group",
            field: "group.name",
            editable: "never"
        },
        {
            title: "Type d'affichage",
            field: "display_type",
            type: "string"
        },
        {
            title: "Custom",
            field: "is_custom",
            editable: "never",
            type: "boolean",
            groupRender: (groupKey) => groupRender(groupKey, <CheckCircle style={{color: "green"}}/>, <Cancel
                style={{color: "red"}}/>),
            render: (data) => data.is_custom ? <CheckCircle htmlColor='green' /> : <Cancel htmlColor='red' />

        },
        {
            title: "Au Titre",
            field: "in_title",
            type: "boolean",
            groupRender: (groupKey) => groupRender(groupKey, <CheckCircle style={{color: "green"}}/>, <Cancel
                style={{color: "red"}}/>),
            render: (data) => data.in_title ? <CheckCircle htmlColor='green' /> : <Cancel htmlColor='red' />
        },
        {
            title: "Vedette",
            field: "featured",
            type: "boolean",
            groupRender: (groupKey) => groupRender(groupKey, <CheckCircle style={{color: "green"}}/>, <Cancel
                style={{color: "red"}}/>),
            render: (data) => data.featured ? <CheckCircle htmlColor='green' /> : <Cancel htmlColor='red' />
        },
        {
            title: "Obligatoire",
            field: "required",
            type: "boolean",
            groupRender: (groupKey) => groupRender(groupKey, <CheckCircle style={{color: "green"}}/>, <Cancel
                style={{color: "red"}}/>),
            render: (data) => data.required ? <CheckCircle htmlColor='green' /> : <Cancel htmlColor='red' />
        },
        {
            title: "Numérique",
            field: "number_only",
            type: "boolean",
            groupRender: (groupKey) => groupRender(groupKey, <CheckCircle style={{color: "green"}}/>, <Cancel
                style={{color: "red"}}/>),
            hidden: true,
            render: (data) => data.number_only ? <CheckCircle htmlColor='green' /> : <Cancel htmlColor='red' />
        },
        {
            title: "Scroll",
            field: "scroll",
            hidden: true,
            type: "boolean",
            groupRender: (groupKey) => groupRender(groupKey, <CheckCircle style={{color: "green"}}/>, <Cancel
                style={{color: "red"}}/>),
            render: (data) => data.scroll ? <CheckCircle htmlColor='green' /> : <Cancel htmlColor='red' />
        },
        {
            title: "Créé le",
            field: "created_date",
            type: "datetime",
            grouping: false,
            filtering: false,
            hidden: true,
            width: '15%',
            editable: "never"
        },
        {
            title: "Dernière modif",
            field: "updated_date",
            type: "datetime",
            grouping: false,
            filtering: false,
            hidden: true,
            width: '15%',
            editable: "never"
        }
    ];

    const attributeFormFields = attributesFields(catId)

    return (
        <>
            <AppTabs value={tabValue} onChange={handleTabChange}>
                <AppTab label="Attributs" {...a11yProps(0)} />
            </AppTabs>

            <TabPanel value={tabValue} index={0}>
                <AttributesContextProvider>
                    <ListRecords
                        title='Attributs'
                        ctx={AttributesContext}
                        columns={attributeColumns}
                        creatable={{
                            type: 'popup',
                            formProps: {
                                id: 'new',
                                renderFields: attributeFormFields,
                                fixedFields: [{field: 'category_id', value: catId}],
                                ctx: AttributesContext,
                                successCreateMsg: 'Attribut ajoutée avec succès',
                                successUpdateMsg: 'Attribut modifiée avec succès',
                                createApi: new URL(`attributes/new`, BASE_URL),
                                addAndEdit: true,
                            },
                        }}
                        updatable={{type: 'inline', url: rAttributes.pathname, msg: "Attribut modifée"}}
                        saveAndNewCallback={(res, e) => navigate(`/categories/${catId}/attributes/${res}`)}
                        deletable={{type: 'inline', url: 'attributes', msg: 'Attribut supprimée avec succès'}}
                        options={{grouping: true, pageSize: 100, pageSizeOptions: [100, 200, 500]}}
                        onRowClick={(event, rowData) => {navigate(`/categories/${catId}/attributes/${rowData?.id}`)}}
                    />
                </AttributesContextProvider>
            </TabPanel>
        </>
    )
}
