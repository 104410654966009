import React, {PropsWithChildren, useEffect, useState} from "react";
import {renderHelperProps} from "../../types";
import {TextInput} from "./Widgets/TextInput";
import {Many2OneWidget} from "./Widgets/Many2One";
import {BooleanWidget} from "./Widgets/Boolean";
import {Picture} from "./Widgets/Picture";
import {Pictures} from "./Widgets/Pictures";
import {PictureCommon} from "../../client";
import {useFormContext} from "../../context/FormContext";
import {Many2Many} from "./Widgets/Many2Many";
import {Many2ManyColors} from "./Widgets/Many2ManyColors";
import {BidAttributes} from "./Widgets/BidAttributes";

export function RenderHelper<T extends { [key: string]: any, pictures?: PictureCommon[] }>(
    {
        item,
        data
    }: PropsWithChildren<renderHelperProps<T>>
): JSX.Element {

    const {mode} = useFormContext()

    const [localData, setLocalData] = useState<T | undefined>(data)

    useEffect(() => {
        setLocalData(data)
        return () => {
            setLocalData(undefined)
        }
    }, [mode])

    const defaultData = localData ? localData[item.field.toString()] : undefined

    switch (item.type) {
        case "text":
        case "number":
        case "float":
        case "password":
        case "date":
            return <TextInput<T>
                item={item}
                defaultValue={defaultData as string}
                type={item.type === "float" ? "number" : item.type}
                step={item.type === "float" ? item.step : undefined}
            />

        case "boolean":
            return <BooleanWidget item={item} defaultValue={defaultData}/>

        case "picture":
            return <Picture
                inputName={item.field.toString()}
                defaultValue={localData ? localData['picture'] : undefined}
            />

        case "many2one":
            const obj = item.localObject as string

            return <Many2OneWidget<T, T[typeof obj]>
                item={item}
                defaultSelected={localData ? localData[obj] : undefined}
                externalLink={item.externalLink}
                filterFunc={item.filterFunc}
            />

        case "many2many":
            return <Many2Many item={item} defaultSelected={defaultData}/>

        case "many2many_colors":
            return <Many2ManyColors item={item} defaultSelected={defaultData}/>

        case "pictures":
            return (
                <Pictures
                    maxItems={item.maxItems}
                    defaultValue={
                        localData && localData.pictures && localData.pictures?.length > 0 ?
                            localData.pictures.map((p, i) => p.picture as string) : undefined
                    }
                />
            )

        case "attributes":
            return (
                <fieldset name="bid_attributes" style={{border: 'none', padding:0}}>
                    <BidAttributes catId={item.catId} values={localData && localData['attribute_values']}/>
                </fieldset>
            )
        case "long_text":
            return <TextInput<T> item={item} defaultValue={defaultData as string} multiline minRows={5}/>

        default:
            return <></>
    }
}
