import {ModelCommon, NestedModelCommon, SingleBidForEdit} from "../../../client";
import {useFormContext} from "../../../context/FormContext";
import React, {useEffect, useState} from "react";
import {TFormField} from "../../../types";
import {rStores} from "../../../Requests/backend_routes";
import {Many2OneWidget} from "./Many2One";
import {BASE_URL} from "../../../Requests/requests";
import {FieldRenderer} from "../FieldRenderer";
import {Launch} from "@mui/icons-material";
import {Link} from "react-router-dom";

type TBidAttributes = {
    bid?: SingleBidForEdit;
    onChangeCategory: (value: any) => void
}

export function BidCategorySelector(props: TBidAttributes): JSX.Element {

    const {mode} = useFormContext()

    if (mode === "update" && !props.bid) throw Error('Unexpected error')
    const [store, setStore] = useState<ModelCommon | undefined>(props.bid?.store)
    const [categoryApi, setCategoryApi] = useState<URL | undefined>(undefined)

    const storeIdField: TFormField<SingleBidForEdit> = {
        field: 'store_id',
        type: "many2one",
        label: "Boutique",
        create: "required",
        update: "readonly",
        order: 1,
        relationalAPI: rStores,
        localObject: 'store',
        localValue: 'name',
        externalLabelKey: 'name',
        position: 'Left',
        onChange: (e, value) => {
            setCategoryApi(undefined);
            setStore(value)
        }
    }

    useEffect(() => {
        setCategoryApi(new URL(`stores/${store?.id}/categories`, BASE_URL))
        return () => {
            setCategoryApi(undefined)
        }
    }, [store])

    const categoryField: TFormField<SingleBidForEdit> = {
        field: 'category_id',
        type: "many2one",
        label: "Category",
        create: "required",
        update: "readonly",
        order: 1,
        relationalAPI: categoryApi as URL,
        localObject: 'category',
        localValue: 'full_name',
        externalLabelKey: 'full_name',
        position: 'Left',
        onChange: (e: any, value: any) => props.onChangeCategory({store: store, category: value}),
    }

    return <>
        {mode === "view" && props.bid &&(
            <FieldRenderer
                label='Domain'
                readonly={true}
                editComponent={<></>}
                text={props.bid?.domain.full_name}
                extra={
                    <Link to={`/domains/${props.bid?.domain.id}`}>
                        <Launch/>
                    </Link>}
            />)}
        <Many2OneWidget<SingleBidForEdit, ModelCommon>
            item={storeIdField}
            defaultSelected={props.bid?.store}
            externalLink='/stores'
        />

        {((store && categoryApi) || props.bid) && (
            <Many2OneWidget<SingleBidForEdit, NestedModelCommon>
                item={categoryField}
                defaultSelected={props.bid?.category}
                externalLink='/categories'
                nested
            />)}
    </>
}
