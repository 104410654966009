import {useParams} from "react-router-dom";
import createGenericContext from "../../../context/FetchContext";
import {PublicStoreFull} from "../../../client";
import {rDomainsContainers, rStores, rStoreTypes, rUsers} from "../../../Requests/backend_routes";
import {BASE_URL} from "../../../Requests/requests";
import React, {SyntheticEvent} from "react";
import {TFormField} from "../../../types";
import {AdminFormSample} from "../../form/AdminFormSample";
import {FormContextProvider} from "../../../context/FormContext";
import {StoreTabsController} from "./store_tabs_controller";
import {useNavigate} from "react-router";
import {formStore} from "../../../formStore";

let storeReducer = formStore<PublicStoreFull>()

export function StoreController(): JSX.Element {


    const {id} = useParams();
    const navigate = useNavigate()
    const {
        MyContext,
        FetchContextProvider
    } = createGenericContext<PublicStoreFull>(
        {
            getApi: new URL(`${rStores.pathname}/${id}`, BASE_URL),
            defaultT: {}
        }
    )

    const fields: TFormField<PublicStoreFull>[] = [
        {
            field: "name",
            label: 'Nom',
            placeHolder: "Nom",
            create: "required",
            update: "optional",
            position: 'Left',
            order: 2,
            type: "text"
        },
        {
            field: "s_type",
            label: "Type de store",
            placeHolder: "Spécifier le type de store",
            create: "optional",
            update: "optional",
            position: 'Left',
            order: 4,
            type: "many2one",
            relationalAPI: rStoreTypes,
            localObject: "type",
            localValue: "name",
            externalLabelKey: "name"

        },
        {
            field: "domain_id",
            label: "Domain",
            placeHolder: "Spécifier le domain de store",
            create: "required",
            update: "readonly",
            position: 'Left',
            order: 1,
            type: "many2one",
            relationalAPI: rDomainsContainers,
            localObject: "domain",
            localValue: "full_name",
            externalLabelKey: "full_name",
            filterFunc: (item) => item.parent_id !== null,
            externalLink: '/domains'
        },
        {
            field: "owner_id",
            label: "Propriétaire",
            placeHolder: "Spécifier le Propriétaire de store",
            create: "required",
            update: "optional",
            position: 'Left',
            order: 3,
            type: "many2one",
            relationalAPI: rUsers,
            localObject: "owner",
            localValue: "full_name",
            externalLabelKey: "full_name",
            externalLink: '/users'

        },
        {
            field: "picture",
            create: "optional",
            update: "optional",
            position: 'Right',
            order: 1,
            type: "picture"
        },
        {
            field: "website",
            label: 'Site web',
            placeHolder: "Entrer le site web de store",
            create: "optional",
            update: "optional",
            position: 'Left',
            excludeIfEmpty: true,
            order: 5,
            type: "text"
        },
        {
            field: "facebook_id",
            label: 'Facebook',
            placeHolder: "Lien de page Facebook",
            create: "optional",
            update: "optional",
            excludeIfEmpty: true,
            position: 'Left',
            order: 6,
            type: "text"
        },
        {
            field: "instagram_id",
            label: 'Instagram',
            placeHolder: "Lien de page Instagram",
            create: "optional",
            update: "optional",
            position: 'Left',
            excludeIfEmpty: true,
            order: 7,
            type: "text"
        },
        {
            field: "tiktok_id",
            label: 'Tiktok',
            placeHolder: "Lien de page Tiktok",
            create: "optional",
            update: "optional",
            position: 'Left',
            excludeIfEmpty: true,
            order: 7,
            type: "text"
        },
        {
            field: "pictures",
            create: "optional",
            update: "optional",
            position: 'Right',
            order: 5,
            type: "pictures",
            maxItems: 5
        },
        {
            field: "active",
            label: "Actif?",
            create: "optional",
            update: "optional",
            position: 'Header',
            order: 1,
            type: "boolean"
        },
        {
            field: "floor",
            label: 'Etage',
            placeHolder: "Spécifier l'étage (0 = rez de chaussé)",
            create: "optional",
            update: "optional",
            position: 'Left',
            order: 11,
            type: "number"
        },
        {
            field: "number",
            label: 'Numéro',
            placeHolder: "Numéro de boutique",
            create: "optional",
            update: "optional",
            position: 'Left',
            order: 12,
            type: "number"
        },
    ]

    async function afterDone(res: any, e: SyntheticEvent<HTMLFormElement, SubmitEvent>) {

        const uid = parseInt(res)
        if (!isNaN(uid) && id === "new") navigate(`/stores/${uid}`)
    }

    return (
        <>
            <FetchContextProvider>
                <FormContextProvider startMode={id === "new" ? 'create' : undefined}>
                    <AdminFormSample<PublicStoreFull>
                        id={id as string}
                        ctx={MyContext}
                        baseApi={rStores}
                        renderFields={fields}
                        successCreateMsg="Boutique ajoutée avec succès"
                        successUpdateMsg="Boutique modifiée avec succès"
                        callBack={afterDone}
                        reducer={storeReducer}
                    />
                </FormContextProvider>
            </FetchContextProvider>
            <StoreTabsController id={id as string} storeReducer={storeReducer}/>
        </>
    )
}
