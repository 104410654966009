import React from "react";
import {useParams} from "react-router-dom";
import createGenericContext from "../../../context/FetchContext";
import {AttributeValueWithParent} from "../../../client";
import {rAttributesValues} from "../../../Requests/backend_routes";
import {BASE_URL} from "../../../Requests/requests";
import {TFormField} from "../../../types";
import {AdminFormSample} from "../../form/AdminFormSample";
import {FormContextProvider} from "../../../context/FormContext";


export function attributesValuesFields(catId: string): TFormField<AttributeValueWithParent>[] {
    return [
        {
            field: 'name',
            label: 'Nom',
            placeHolder: "Nom de l'attribut",
            create: 'required',
            update: 'optional',
            type: 'text',
            position: 'Left',
            order: 2
        },
        {
            field: 'sequence',
            label: "Ordre",
            placeHolder: "Ordre de l'attribut (utilisé pour définir la periorité des attributs)",
            create: 'optional',
            update: 'optional',
            type: 'number',
            position: 'Left',
            order: 3
        },
        {
            field: 'picture',
            create: 'optional',
            update: 'optional',
            type: 'picture',
            position: 'Right',
            order: 1

        },
        {
            field: 'attribute_id',
            label: 'Attribut parente',
            create: 'hidden',
            update: 'readonly',
            type: 'many2one',
            position: 'Left',
            order: 1,
            localObject: 'parent_attribute',
            localValue: 'name',
            externalLabelKey: 'name',
            relationalAPI: new URL(`categories/${catId}/attributes`, BASE_URL),
        }
    ]

}

export function AttributeValueController(): JSX.Element {

    const {catId, attrId, id} = useParams();
    const {
        MyContext,
        FetchContextProvider
    } = createGenericContext<AttributeValueWithParent>(
        {
            getApi: new URL(`${rAttributesValues.pathname}/${id}`, BASE_URL),
            defaultT: undefined
        }
    )

    return (
        <>
            <FetchContextProvider>
                <FormContextProvider startMode={id === "new" ? 'create' : undefined}>
                    <AdminFormSample<AttributeValueWithParent>
                        id={id as string}
                        baseApi={rAttributesValues}
                        renderFields={attributesValuesFields(catId as string)}
                        fixedFields={[{field: 'attribute_id', value: attrId}]}
                        ctx={MyContext}
                        successCreateMsg="Attribut ajoutée avec succès"
                        successUpdateMsg="Attribut modifiée avec succès"
                    >
                    </AdminFormSample>
                </FormContextProvider>
            </FetchContextProvider>
        </>
    )
}
