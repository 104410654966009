import {Box, Fab, FormControl} from "@mui/material"
import {AddCircle} from "@mui/icons-material"
import {useEffect, useState} from "react"
import {PicturesWidgetProps} from "../../../types";
import {useFormContext} from "../../../context/FormContext";
import {Picture} from "./Picture";


export const Pictures = ({defaultValue, maxItems}: PicturesWidgetProps) => {

    const [pictures, setPictures] = useState<(string | undefined)[]>(
        defaultValue ? defaultValue : []
    )

    const {mode} = useFormContext()

    useEffect(() => {
        setPictures(defaultValue ? defaultValue : [])
    }, [mode])

    function addNewItem() {
        setPictures([...pictures, undefined])
    }

    return (
        <Box sx={{position: 'relative'}} margin='15px 0'>
            <h4> Images supplementaires </h4>
            <FormControl
                component="fieldset"
                name="pictures"
                fullWidth
                sx={{
                    position: 'relative',
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'flex-end',
                    flexDirection: 'row'
            }}
            >
                {
                    pictures?.map((picture, index) => (
                            <Picture
                                inputName={`pic-${index}`}
                                key={index}
                                unmountable
                                defaultValue={picture}
                            />
                        )
                    )
                }
            </FormControl>
            {pictures?.length >= 0 && pictures?.length < maxItems && mode !== 'view' && (

                <Fab
                    size="small"
                    color="success"
                    onClick={addNewItem}>
                    <AddCircle/>
                </Fab>
            )}
        </Box>
    )
}
