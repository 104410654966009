import {useEffect, useState} from "react";
import {BooleanWidgetProps} from "../../../types";
import {FormControl, FormControlLabel} from "@mui/material";
import {useFormContext} from "../../../context/FormContext";
import Switch from "@mui/material/Switch";


export function BooleanWidget<T>({item, defaultValue}: BooleanWidgetProps<T>): JSX.Element {
    const [initialValue, setInitialValue] = useState<boolean | undefined>(
        defaultValue ? defaultValue : false
    )
    const {mode} = useFormContext()

    const required = mode === "view" ? false : item[mode] === 'required'
    const readonly = mode === "view" ? true : item[mode] === 'readonly'
    const hidden = mode === "view" ? item.hidden : item[mode] === 'hidden'

    useEffect(
        () => {
            setInitialValue(defaultValue)
        },
        [mode]
    )
    return (
        <FormControl margin="normal">
            {!hidden && (
                <FormControlLabel
                    control={
                        <Switch
                            size="medium"
                            name={item.field.toString()}
                            defaultChecked={initialValue}
                            disabled={readonly}
                            required={required}
                        />
                    }
                    label={item.label}
                />
            )}
        </FormControl>
    )
}
