import {TResponse} from "../types";
import {Dispatch, SetStateAction} from "react";


export const BASE_URL: URL = new URL(process.env.REACT_APP_API_URL as string);
export const IMAGES_URL: URL = new URL(process.env.REACT_APP_IMAGES_URL as string);


export const FormHeaders: HeadersInit = {
    "content-type": "application/x-www-form-urlencoded",
    "accept": "application/json",
}

export const JsonHeaders: HeadersInit = {
    'content-type': "application/json",
    "accept": "application/json"
}

export function AuthHeaders(): HeadersInit {
    return {
        "content-type": "application/json",
        "accept": "application/json",
        "authorization": `bearer ${localStorage.token}`
    }
}

export const FormOptions: RequestInit = {
    method: "POST",
    headers: FormHeaders
};

export const GetOptions: RequestInit = {
    method: "GET",
    headers: JsonHeaders

};

export function AuthGetOptions(): RequestInit {
    return {
        method: "GET",
        headers: AuthHeaders()
    }
}
;

export function PostOptions(): RequestInit {
    return {
        method: "POST",
        headers: AuthHeaders()
    }
}

export function deleteOptions(): RequestInit {
    return {
        method: "DELETE",
        headers: AuthHeaders()
    }
}

export function PutOptions(): RequestInit {
    return {
        method: "PUT",
        headers: AuthHeaders()
    }
}


export async function ResponseHandler<T>(
    res: Response,
    onReject: (err: TResponse) => void | (() => void),
    setter?: Dispatch<SetStateAction<T>>
): Promise<any> {

    switch (res.status) {
        case 200: {
            const data: Awaited<T> = await res.json();
            setter && setter(data)
            return data
        }
        case 400:
        case 422:
        case 401:
        case 403:
        case 404:
        case 500: {
            const err: Awaited<TResponse> = await res.json()
            onReject && onReject(err);
            return err
        }

        default:
            return
    }
}
