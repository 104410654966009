import React from "react";
import {TextField} from "@mui/material";
import Typography from "@mui/material/Typography";
import {AttributeFull, BidAttributesValues} from "../../../../client";
import {useFormContext} from "../../../../context/FormContext";
import {FieldRenderer} from "../../FieldRenderer";


type TTexte = {
    attribute: AttributeFull
    defaultValue?: BidAttributesValues
}

export function Texte(props: TTexte): JSX.Element {

    const {mode} = useFormContext()

    const required = mode === "view" ? false : props.attribute.required

    return (
        <FieldRenderer
            label={required ? <>
                {props.attribute.name}
                <Typography
                    component='span'
                    color='primary'>*</Typography>
            </> : props.attribute.name}
            text={props.defaultValue?.name}
            editComponent={
                <TextField
                    id={`id-${props.attribute.id.toString()}`}
                    name={props.defaultValue ? props.defaultValue?.id.toString() : props.attribute.id.toString()}
                    placeholder={props.attribute.placeholder}
                    required={required}
                    defaultValue={props.defaultValue?.name}
                    variant='standard'
                    fullWidth
                    inputProps={{datatype: 'custom'}}
                />
            }
            readonly={mode === "view"}
        />

    )
}
