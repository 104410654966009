import MaterialTable, {Action, MaterialTableProps} from "@material-table/core";
import {SyntheticEvent, useContext, useEffect, useMemo, useState} from "react";
import {BASE_URL, deleteOptions, PostOptions, PutOptions} from "Requests/requests";
import {Alert, CircularProgress, Dialog} from "@mui/material";
import {getDifference, unpack_nested} from "tools/tools";
import {TFetchContext, TFormProps, TListRecordProps} from "../../types";
import {useNavigate} from "react-router";
import {NavigateFunction} from "react-router-dom";
import {AddBox} from "@mui/icons-material";
import {AdminFormSample} from "../form/AdminFormSample";
import {FormContextProvider} from "../../context/FormContext";


type TLocalState<T extends object> = {

    data: T[]
    selected: T[]
    isCreatable?: { type: 'inline', api: string } | { type: 'newWindow', url: string } | { type: 'form', formProps: TFormProps<T> }
    isUpdatable?: { type: 'inline', api: string } | { type: 'newWindow', url: string } | { type: 'form', formProps: TFormProps<T> }
    isDeletable?: { type: 'inline', api: string } | { type: 'newWindow', url: string } | { type: 'form', formProps: TFormProps<T> }
    editable?: MaterialTableProps<T>['editable']
    actions?: MaterialTableProps<T>['actions']
    dialog: boolean
}


function ListRecordsRenderer<T extends { id: number } & object>(
    {
        columns,
        options,
        actions,
        ...props
    }: TListRecordProps<T> & { response: T[] }): JSX.Element {
    function addAction<T extends object>(onClick: (...args: any) => void): Action<T> {
        return {
            icon: () => <AddBox/>,
            tooltip: "Add new",
            isFreeAction: true,
            onClick: onClick
        }
    }

    const navigate: NavigateFunction = useNavigate();

    const {fetchForCUD} = useContext(props.ctx)
    const [state, setState] = useState<TLocalState<T>>({
        data: props.response ?? props.response,
        selected: [],
        isCreatable: undefined,
        isUpdatable: undefined,
        isDeletable: undefined,
        dialog: false
    })

    useEffect(() => {
        if (props.response) {
            let _state: TLocalState<T> = {...state}
            let _data: T[] = []
            if (props.nestedData) {
                unpack_nested(props.response as T[], _data)
            } else {
                _data = props.response as T[]
            }

            if (props.rebuildFunc) {
                if (_data != null && _data.length > 0) _data = props.rebuildFunc(_data)
            }
            if (props.creatable) {
                let addIconAction: Action<T>
                switch (props.creatable.type) {
                    case "inline":
                        const url = new URL(props.creatable.url, BASE_URL)
                        const createMsg = props.creatable.msg
                        _state = {
                            ..._state,
                            editable: {
                                ..._state.editable,
                                onRowAdd: async (newData: T) =>
                                    await fetchForCUD(
                                        url,
                                        {...PostOptions(), body: JSON.stringify(newData)},
                                        createMsg
                                    )
                            }
                        }
                        break
                    case "newWindow":
                        const urlLocale = props.creatable.url
                        addIconAction = addAction<T>(() => {
                            navigate(urlLocale)
                        })
                        _state = {..._state, actions: [addIconAction]}
                        break
                    case "popup":
                        addIconAction = addAction<T>(() => setState({...state, dialog: true}))
                        _state = {..._state, actions: [addIconAction]}
                        break
                    default:
                        break
                }
            }
            if (props.updatable) {
                switch (props.updatable.type) {
                    case "inline":
                        const updateMsg = props.updatable.msg
                        const url = props.updatable.url
                        _state = {
                            ..._state,
                            editable: {
                                ..._state.editable,
                                onRowUpdate: async (updateData: T, oldData) =>
                                    await fetchForCUD(
                                        new URL(`${url}/${updateData.id}`, BASE_URL),
                                        {...PutOptions(), body: JSON.stringify(getDifference(oldData as T, updateData))},
                                        updateMsg
                                    )
                            }
                        }
                        break
                }
            }
            if (props.deletable) {
                switch (props.deletable.type) {
                    case "inline":
                        const deleteMsg = props.deletable.msg
                        const url = props.deletable.url
                        _state = {
                            ..._state,
                            editable: {
                                ..._state.editable,
                                onRowDelete: async (oldData: T) =>
                                    await fetchForCUD(
                                        new URL(`${url}/${oldData.id}`, BASE_URL),
                                        deleteOptions(),
                                        deleteMsg
                                    )
                            }
                        }
                        break
                }
            }

            setState({..._state, data: _data})
        }


    }, [props.response, state.dialog])

    async function handleCallBack(res: any, e: SyntheticEvent<HTMLFormElement, SubmitEvent>) {
        e.preventDefault()

        const ele = e.target as HTMLFormElement
        ele.reset()
        if (e.nativeEvent.submitter?.getAttribute('name') === "save") {
            setState({...state, dialog: true})
        }

        if (e.nativeEvent.submitter?.getAttribute('name') === "save_and_edit") {
            setState({...state, dialog: false})
            props.saveAndNewCallback && props.saveAndNewCallback(res, e)
        }
    }

    return <>
        {props.creatable?.type === "popup" && (
            <Dialog
                open={state.dialog}
                maxWidth='xl'
                onClose={() => setState({...state, dialog: false})}
                fullWidth
            >
                <FormContextProvider startMode='create'>
                    <AdminFormSample
                        callBack={handleCallBack}
                        {...(props.creatable.type === "popup" && {
                            callBackCancel: () => setState({
                                ...state,
                                dialog: false
                            })
                        })}
                        {...props.creatable.formProps}
                    />
                </FormContextProvider>
            </Dialog>
        )}
        <MaterialTable<T>
            columns={columns}
            data={state.data}
            editable={state.editable}
            options={{
                actionsColumnIndex: -1,
                columnsButton: true,
                columnResizable: true,
                showGroupingCount: true,
                pageSize: 20,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [20, 50, 100],
                actionsCellStyle: {width: '2%'},
                ...options
            }}
            actions={[...(state.actions ? state.actions : []), ...(actions ? actions : [])]}
            {...props}
        />
    </>
}

export function ListRecords<T extends { id: number } & object>(props: TListRecordProps<T>): JSX.Element {

    const {useGetData} = useContext<TFetchContext<T>>(props.ctx);
    const {response, loading, err} = useGetData(undefined, props.skip, props.limit);

    const localData = useMemo(() => response, [response, loading])

    if (loading) return <CircularProgress/>
    if (err)
        return (
            <Alert severity="error" sx={{width: "100%"}}>
                Erreur est survenue !
            </Alert>
        );


    return (
        <>
            <ListRecordsRenderer {...props} response={localData as T[]}/>
        </>
    )
}
