import React, {useEffect, useState} from "react";
import {Checkbox, CircularProgress, FormControlLabel} from "@mui/material";
import {AttributeFull, BidAttributesValues} from "../../../../client";
import {useFormContext} from "../../../../context/FormContext";
import {getData} from "../../../../context/FetchContext";
import {BASE_URL} from "../../../../Requests/requests";


type TManyMany = {
    attribute: AttributeFull
    values?: BidAttributesValues[]
}

export function ManyMany(props: TManyMany) {

    const [list, setList] = useState<readonly BidAttributesValues[] | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(true)

    const {mode} = useFormContext()

    useEffect(
        () => {
            (async () => await getData<BidAttributesValues>(new URL(`attributes/${props.attribute.id}/attributes_values`, BASE_URL), 0, 1000, setList))()
            setLoading(false)
        }, []
    );

    if (loading) return <CircularProgress/>

    return (
        <div style={{position: "relative"}}>
            <div className="customLegend">
                <h1> <span> {props.attribute.name} </span> </h1>
                <div>
                    {
                        list?.map(
                            (record) =>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            defaultChecked={
                                                !!props.values?.find((item) => item.id === record.id)
                                            }
                                            name={props.attribute.id.toString()}
                                            value={record.id}
                                            disabled={mode === "view"}
                                            inputProps={{datatype: 'manyMany'}}
                                        />
                                    }
                                    label={record.name}
                                    key={record.id}
                                />
                        )
                    }
                </div>
            </div>
        </div>
    );
}
