import {AttributeFull, BidAttributesValues} from "../../../../client";
import {OneMany} from "./oneMany";
import {ManyMany} from "./manyMany";
import {Texte} from "./texte";

type TAttributeWidget = {

    attribute: AttributeFull
    bidValues?: BidAttributesValues[]

}

export function AttributeWidget(props: TAttributeWidget): JSX.Element {

    switch (props.attribute.display_type) {
        case "OneManyAvatar":
        case "OneMany":
            return (
                <OneMany
                    attribute={props.attribute}
                    value={props.bidValues?.find(
                        (attrValue) => attrValue.attribute_id === props.attribute.id)
                    }
                />
            )
        case "Texte":
            return (
                <Texte
                    attribute={props.attribute}
                    defaultValue={
                        props.bidValues?.find((attrValue) => attrValue.attribute_id === props.attribute.id)
                    }
                />
            )
        case "ManyMany":
        case "ManyManyChip":
            return (
                <ManyMany
                    attribute={props.attribute}
                    values={props.bidValues?.filter((v) => v.attribute_id === props.attribute.id)}
                />
            )
    }
    return <>
    </>
}
