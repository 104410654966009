import MaterialTable, {Column, MaterialTableProps} from "@material-table/core";
import {Alert, Button, CircularProgress, Grid, Paper} from "@mui/material";
import {Context, useContext, useEffect, useRef, useState} from "react";
import {PostOptions} from "Requests/requests";
import {unpack_nested} from "../../tools/tools";
import {AppMTableToolbar} from "components/overrides";
import {TFetchContext, X2ManyProps} from "../../types";


type TManager<T extends { id: number, tableData?: any, children?: any } & object> = {
    data: T[]
    selected: T[]
    columns: Column<T>[]
    saveApi: URL
    successMsg: string
    ctx: Context<TFetchContext<T>>
} & Omit<MaterialTableProps<T>, 'data' & 'columns'>

export function X2ManyManager<T extends { id: number, tableData?: any, children?: any } & object>(
    {ctx, data, selected, columns, options, saveApi, successMsg, ...props}: TManager<T>
): JSX.Element {

    const [editMode, setEditMode] = useState<boolean>(false)
    const [saving, setSaving] = useState<boolean>(false)

    const {fetchForCUD} = useContext(ctx)

    const tableRef = useRef(null)

    async function saveData(ids: number[]) {
        setSaving(true)
        setEditMode(false)
        const options: RequestInit = PostOptions()
        options.body = JSON.stringify(ids)
        await fetchForCUD(
            saveApi,
            options,
            successMsg
        )
        setSaving(false)
    }

    return (
        <>
            {saving && (<CircularProgress/>)}
            {!saving && (
                <MaterialTable<T>
                    components={{
                        Toolbar: ({...props}) => {
                            return (

                                <Grid container>
                                    <Grid item xs={8} sx={{
                                        '& .MuiToolbar-gutters': {
                                            backgroundColor: 'none'
                                        }
                                    }}>
                                        <AppMTableToolbar {...props}/>
                                    </Grid>
                                    <Grid xs={4} direction='row-reverse' container item alignContent='center'>
                                        <>
                                            {
                                                editMode && (
                                                    <>
                                                        <Button
                                                            variant="outlined"
                                                            onClick={() => setEditMode(false)}
                                                        >
                                                            Annuler
                                                        </Button>
                                                        <Button
                                                            variant="contained"
                                                            onClick={
                                                                async () => {
                                                                    const t: any = tableRef.current
                                                                    const selectedRows: T[] = t.dataManager.data.filter(
                                                                        (o: any) => o.tableData.checked && !o.tableData.childRows
                                                                    )
                                                                    let data_ids = selectedRows.filter(
                                                                        (selected_row) =>
                                                                            !selected_row.children || selected_row.children.length <= 0).map(
                                                                        (item) => item.id
                                                                    )
                                                                    await saveData(data_ids)
                                                                }
                                                            }
                                                            type='button'
                                                        >
                                                            Sauvegarder
                                                        </Button>
                                                    </>
                                                )
                                            }
                                            {!editMode && (
                                                <Button
                                                    variant="contained"
                                                    onClick={() => setEditMode(true)}
                                                >
                                                    Modifier
                                                </Button>
                                            )}
                                        </>

                                    </Grid>
                                    {props.children}
                                </Grid>
                            )
                        },
                        Container: ({children, ...props}) => (
                            <Paper
                                variant="elevation"
                                elevation={0}
                                sx={{borderRadius: '0'}}
                                {...props}
                            >
                                {children}
                            </Paper>
                        ),

                    }}
                    columns={columns}
                    data={data}
                    options={{
                        ...options,
                        selectionProps: {
                            disabled: !editMode
                        },
                        headerSelectionProps: {
                            disabled: !editMode
                        },
                    }}
                    tableRef={tableRef}
                    {...props}
                />
            )}
        </>
    )

}

export function X2Many<T extends { id: number, tableData?: any } & object>(
    {
        dataCtx,
        selectedCtx,
        saveApi,
        nestedData,
        columns,
        successMsg,
        nestedSelected,
        ...props
    }: X2ManyProps<T> & Omit<MaterialTableProps<T>, 'data'>): JSX.Element {

    const [data, setData] = useState<{ data?: T[], selected?: T[] }>({data: undefined, selected: undefined})

    const {useGetData} = useContext(dataCtx);
    const {response, loading, err} = useGetData();

    const {useGetData: useGetSelected} = useContext(selectedCtx);
    const {response: resSelected, loading: loadingSelected, err: errSelected} = useGetSelected();

    useEffect(() => {

        let unpacked_data: T[] = []

        if (response && resSelected) {
            if (nestedData) {
                unpack_nested(response as T[], unpacked_data)
            } else {
                unpacked_data = response as T[]
            }

            unpacked_data.map(
                (item) => item.tableData = {checked: false}
            )

            let unpackedSelected: T[] = []

            if (nestedSelected) {
                unpack_nested(resSelected as T[], unpackedSelected)
            } else {
                unpackedSelected = resSelected as T[]
            }

            unpackedSelected.forEach((item) => {
                let found_in_data = unpacked_data.find(
                    (data_item) => data_item.id === item.id
                )
                if (found_in_data) {
                    unpacked_data[unpacked_data.indexOf(found_in_data)].tableData = {checked: true}
                }
            });
            setData({data: unpacked_data, selected: unpackedSelected})
        }

        return () => {
            setData({data: undefined, selected: undefined})
        }

    }, [response, resSelected]);

    if (loading || loadingSelected) return <CircularProgress/>;

    if (err || errSelected)
        return (
            <Alert severity="error" sx={{width: "100%"}}>
                Erreur est survenue !
            </Alert>
        );
    return (
        <>
            {data.data && data.selected && (
                <X2ManyManager<T>
                    data={data.data}
                    selected={data.selected}
                    columns={columns}
                    ctx={selectedCtx}
                    saveApi={saveApi}
                    successMsg={successMsg}
                    {...props}
                />
            )}
        </>
    );
}
