import React, {Fragment, PropsWithChildren, SyntheticEvent, useContext, useState} from "react";
import {TFetchContext, TFormField, TFormProps} from "../../types";
import {useFormContext} from "../../context/FormContext";
import {SavingButtons} from "../special/saving_buttons";
import {CircularProgress, Paper} from "@mui/material";
import {RenderHelper} from "./RenderHelper";
import {BASE_URL, PostOptions, PutOptions} from "../../Requests/requests";
import {buildPicture, convertBase64, getDifference, imageUriToObject} from "../../tools/tools";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import {useNavigate} from "react-router";
import {AttributeValueFull, BidAttributesValues, CustomAttrValuesUpdate, RequestPictureCommon} from "../../client";
import imageCompression, {Options as imgCompressOptions} from "browser-image-compression";

const imgOptions: imgCompressOptions = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true
}

export function AdminForm<GetM extends { [key: string]: any }>(
    {
        id,
        data,
        renderFields,
        fixedFields,
        baseApi,
        createApi,
        updateApi,
        ctx,
        successCreateMsg,
        successUpdateMsg,
        callBack,
        addAndEdit,
        callBackCancel,
        ActionsComponent,
        isPopUp
    }: PropsWithChildren<TFormProps<GetM>>
): JSX.Element {

    const [saving, setSaving] = useState<boolean>(false)
    const {mode, setMode} = useFormContext()

    const navigate = useNavigate()

    const {fetchForCUD} = useContext<TFetchContext<GetM>>(ctx)

    async function handleSubmit(e: SyntheticEvent<HTMLFormElement, SubmitEvent>) {
        e.preventDefault();
        setSaving(true)
        if (mode === 'view') {
            return
        }

        const form: HTMLFormElement = e.target as HTMLFormElement

        let modeOptions = PostOptions()
        let formId = "new"
        let formMsg = mode === "create" ? successCreateMsg : successUpdateMsg

        function form_fields_type<T extends object>(formMode: "create" | "update", fields: TFormField<T>[]): TFormField<T>[] {
            let res: TFormField<T>[] = []
            fields.forEach((field) => {
                field[formMode] !== "hidden" && field[formMode] !== "readonly" && res.push(field)
            })
            return res
        }

        let formObj: { [key: string]: any } = {}

        const usedFields: TFormField<GetM>[] = form_fields_type<GetM>(mode, renderFields)

        for (const field of usedFields) {

            const _name: string = field.field.toString()
            switch (field.type) {
                case "text":
                case "long_text":
                case "password":
                case "date":
                    const textInput: HTMLInputElement = form.elements.namedItem(_name) as HTMLInputElement
                    if (field.excludeIfEmpty && textInput.value === "") continue ;
                    formObj[_name] = textInput.value
                    break;

                case "picture":
                    const fileInput: HTMLInputElement = form.elements.namedItem(_name) as HTMLInputElement
                    if (fileInput.getAttribute('none') !== null) {
                        formObj['picture'] = null
                    } else if (fileInput.files !== null && fileInput.files.length > 0) {
                        const imgFile: File = fileInput.files[0]
                        const compressed: Awaited<File> = await imageCompression(imgFile, imgOptions)
                        formObj['picture'] = await convertBase64(compressed) as string
                    }
                    break;

                case "pictures":
                    const filesInput: HTMLFormElement = form.elements.namedItem('pictures') as HTMLFormElement
                    const pictures: (string | RequestPictureCommon)[] = []
                    for (let idx = 0; idx < filesInput.elements.length; idx++) {
                        const imgFile: HTMLInputElement = filesInput.elements[idx] as HTMLInputElement
                        const files: FileList = imgFile.files as FileList
                        if (files.length > 0) {
                            const compressed: Awaited<File> = await imageCompression(files[0], imgOptions)
                            const uri: Awaited<string> = await convertBase64(compressed) as string
                            pictures.push(imageUriToObject(uri))
                        } else {
                            imgFile.alt !== "" && pictures.push(imgFile.alt)
                        }
                    }
                    formObj['pictures'] = pictures
                    break;

                case "boolean":
                    const boolInput: HTMLInputElement = form.elements.namedItem(_name) as HTMLInputElement
                    formObj[_name] = boolInput.checked
                    break;

                case "number":
                case "float":
                case "many2one":
                    const numberInput: HTMLInputElement = form.elements.namedItem(_name) as HTMLInputElement
                    if (!isNaN(numberInput.valueAsNumber)) formObj[_name] = numberInput.valueAsNumber
                    if (isNaN(numberInput.valueAsNumber) && field.type === "many2one") formObj[_name] = null
                    break;

                case "many2many":
                case "many2many_colors":
                    const checkboxes: RadioNodeList = form.elements.namedItem(_name) as RadioNodeList
                    let selected: number[] = []
                    for (let idx = 0; idx < checkboxes.length; idx++) {
                        const checkbox: HTMLInputElement = checkboxes[idx] as HTMLInputElement
                        checkbox.checked && selected.push(parseInt(checkbox.value))
                    }
                    formObj[_name] = selected
                    break;
                case "attributes":
                    const attributesInput: HTMLFieldSetElement = form.elements.namedItem('bid_attributes') as HTMLFieldSetElement
                    const allAttrs: HTMLCollection = attributesInput.elements
                    const attrValues: number[] = []
                    const customAttrs: (AttributeValueFull | CustomAttrValuesUpdate)[] = []
                    const newCustomAttrs: AttributeValueFull[] = []
                    const _data: BidAttributesValues[] = data?.attribute_values as BidAttributesValues[]
                    for (let idx = 0; idx < allAttrs.length; idx++) {
                        const ele: HTMLInputElement = allAttrs[idx] as HTMLInputElement

                        // Custom attributes
                        if (ele.getAttribute('datatype') === "custom") {
                            // if create mode check if empty only
                            if (mode === "create") {
                                if (ele.value !== "") customAttrs.push({
                                    attribute_id: parseInt(ele.name),
                                    name: ele.value
                                })
                            }
                            if (mode === "update") {
                                const attrValueId: number = parseInt(ele.name)
                                const relatedAttr: BidAttributesValues | undefined = _data.find((bidAttr) => bidAttr.id === attrValueId)

                                if (relatedAttr) {
                                    // this is modified custom attribute value
                                    if (relatedAttr.name !== ele.value) {
                                        customAttrs.push({value_id: parseInt(ele.name), name: ele.value})
                                    } else {
                                        attrValues.push(parseInt(ele.name))
                                    }
                                } else {
                                    if (ele.value !== "") newCustomAttrs.push({
                                        attribute_id: parseInt(ele.name),
                                        name: ele.value
                                    })
                                }
                            }
                        }

                        // ManyMany attributes
                        if (ele.getAttribute('datatype') === "manyMany") {
                            if (ele.checked) attrValues.push(parseInt(ele.value))
                        }

                        // OneMany attributes
                        if (ele.getAttribute('datatype') === "oneMany") {
                            if (ele.value !== "") attrValues.push(parseInt(ele.value))
                        }
                    }
                    formObj['attribute_values'] = attrValues
                    if (customAttrs.length > 0) formObj['custom_attributes'] = customAttrs
                    if (newCustomAttrs.length > 0) formObj['new_custom_attributes'] = newCustomAttrs
                    break;
            }
        }

        let formURL: URL | undefined

        if (mode === "update") {
            formId = data ? data['id'] : 'none'
            modeOptions = PutOptions()
            formObj = getDifference(data as GetM, formObj)
            formURL = updateApi ? updateApi : baseApi ? new URL(`${baseApi.pathname}/${formId}`, BASE_URL) : undefined
        } else {
            formURL = createApi ? createApi : baseApi ? new URL(`${baseApi.pathname}/${formId}`, BASE_URL) : undefined
        }
        if (!formURL) throw Error('error')

        buildPicture(formObj)
        if (fixedFields) {
            fixedFields.forEach((field) => {
                formObj[field.field.toString()] = field.value
            })
        }
        modeOptions.body = JSON.stringify(formObj)
        const [res, status] = await fetchForCUD(formURL, modeOptions, formMsg)

        if (status === 200 && mode === "update") {
            setMode('view')
        }
        setSaving(false)

        return callBack && await callBack(res, e)
    }

    renderFields.sort((a, b) => b.order < a.order ? 1 : -1)

    const headerFields: TFormField<GetM>[] = renderFields.filter((field) => field.position === "Header")
    const rightFields: TFormField<GetM>[] = renderFields.filter((field) => field.position === "Right")
    const leftFields: TFormField<GetM>[] = renderFields.filter((field) => field.position === "Left")

    return (
        <Paper elevation={4} style={{padding: "5px 5px", marginBottom: "10px"}}>
            <form onSubmit={handleSubmit}>
                {/* Buttons */}
                {ActionsComponent && mode !== "create" && id !== "new" && (
                    <>
                        <Grid container spacing={1}>
                            <Grid xs={12} display='flex' flexDirection='row' justifyContent='flex-start'
                                  padding='10px 20px' item>
                                {
                                    <ActionsComponent ctx={ctx} id={id as string} data={data as GetM}/>
                                }
                            </Grid>
                        </Grid>
                        <Divider
                            orientation="horizontal"
                            variant="middle"
                            flexItem
                        />
                    </>
                )}
                {/* Header */}
                {headerFields.length > 0 && (
                    <>
                        <Grid container spacing={1}>
                            <Grid
                                display='flex'
                                flexDirection='row'
                                justifyContent='flex-end'
                                padding='0px 20px'
                                container
                            >
                                {headerFields.map(
                                    (item, index) => {
                                        if (
                                            (mode === "view" && !item.hidden)
                                            ||
                                            (mode !== "view" && item[mode] !== "hidden")
                                        ) {
                                            return (
                                                <RenderHelper<GetM>
                                                    data={data}
                                                    item={item}
                                                    key={index}
                                                />
                                            )
                                        }
                                        return (<Fragment key={index}></Fragment>)
                                    })
                                }
                            </Grid>
                        </Grid>
                        <Divider
                            orientation="horizontal"
                            variant="middle"
                            sx={{margin: '5px 0px 10px 0px'}}
                            flexItem
                        />
                    </>
                )}
                <Grid container spacing={1} justifyContent='space-between'>
                    {/* Left */}
                    <Grid
                        xs={rightFields.length > 0 ? 7 : 12}
                        display='flex'
                        flexDirection='column'
                        justifyContent='flex-start'
                        padding='0px 20px'
                        item
                    >
                        {leftFields.map(
                            (item, index) => {
                                if (
                                    (mode === "view" && !item.hidden)
                                    ||
                                    (mode !== "view" && item[mode] !== "hidden")
                                ) {
                                    return (
                                        <RenderHelper<GetM>
                                            data={data}
                                            item={item}
                                            key={index}
                                        />
                                    )
                                }
                                return (<Fragment key={index}></Fragment>)
                            })
                        }
                    </Grid>

                    {/* Right */}
                    {rightFields.length > 0 && (
                        <>
                            <Divider
                                orientation="vertical"
                                variant="middle"
                                flexItem
                            />
                            <Grid item xs={4} display='flex' flexDirection='column'>
                                {rightFields.map(
                                    (item, index) => {
                                        if (
                                            (mode === "view" && !item.hidden)
                                            ||
                                            (mode !== "view" && item[mode] !== "hidden")
                                        ) {
                                            return (
                                                <RenderHelper<GetM>
                                                    data={data}
                                                    item={item}
                                                    key={index}
                                                />
                                            )
                                        }
                                        return (<Fragment key={index}></Fragment>)
                                    })
                                }
                            </Grid>
                        </>)}
                </Grid>
                {saving && (<CircularProgress/>)}
                {!saving && (
                    <SavingButtons
                        addAndEdit={addAndEdit}
                        onCancel={callBackCancel ? callBackCancel as () => void : () => navigate(-1)}
                        isPopUp={isPopUp}
                    />
                )}
            </form>
        </Paper>
    )
}
