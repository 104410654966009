import {FC} from "react";
import {Avatar, Stack,} from "@mui/material";
import {Cancel, CheckCircle} from "@mui/icons-material";
import {IMAGES_URL} from "Requests/requests";
import createGenericContext from "context/FetchContext";
import {rUsers} from "../../../Requests/backend_routes";
import {AdminUserOut} from "../../../client";
import {Column} from "@material-table/core";
import {ListRecords} from "../../special/list_view";
import {groupRender} from "../../../tools/tools";
import {useNavigate} from "react-router";

const DELETE_MSG = "Utilisateur supprimé";

const {MyContext, FetchContextProvider} = createGenericContext<AdminUserOut>({getApi: rUsers, defaultT: []})

export const Users: FC = () => {

    const navigate = useNavigate()
    const columns: Column<AdminUserOut>[] = [
        {
            title: "ID", field: "id", editable: "never", hidden: true, width: '1%', filtering: false, grouping: false
        },
        {
            field: "is_active",
            title: "Active",
            render: (rowData) => rowData.is_active ? <CheckCircle style={{color: "green"}}/> :
                <Cancel style={{color: "red"}}/>,
            groupRender: (groupKey) => groupRender(groupKey, <CheckCircle style={{color: "green"}}/>, <Cancel
                style={{color: "red"}}/>),
            width: '1%',
            filtering: true,
            type: "boolean"
        },
        {
            field: "picture",
            title: "Image",
            editable: "never",
            render: (rowData) => {
                return <Stack direction="row" spacing={2}>
                    <Avatar
                        src={`${IMAGES_URL}/${rowData.picture}`}
                        sx={{width: 50, height: 50}}
                    />
                </Stack>;
            },
            width: '1%',
            grouping: false,
            filtering: false
        },
        {
            title: "Homme?",
            field: "is_man",
            width: '1%',
            render: (rowData) => rowData.is_man ? <strong> Mâle </strong> : <strong> Female </strong>,
            groupRender: (groupKey) => groupRender(groupKey, <strong> Mâle </strong>, <strong> Female </strong>),
            type: "boolean"
        },
        {title: "Nom", field: "full_name"},
        {title: "Email", field: "email", editable: "never"},
        {
            title: "Tél",
            field: "phone",
            width: '15%'
        },
        {
            title: "Créé le",
            field: "created_date",
            width: '15%', type: 'datetime',
            filtering: false,
            grouping: false,
            editable: "never",
            hidden: true
        },
        {
            title: "Dernière connection",
            field: "last_connected",
            width: '15%',
            type: 'datetime',
            filtering: false,
            grouping: false,
            editable: "never"
        },
        {
            title: "Dernière Modif",
            field: "updated_date",
            width: '15%', type: 'datetime',
            filtering: false,
            grouping: false,
            hidden: true,
            editable: "never"
        },
    ]

    return (
        <FetchContextProvider>
            <ListRecords<AdminUserOut>
                title="Utilisateurs"
                ctx={MyContext}
                deletable={{type: 'inline', url: 'users', msg: DELETE_MSG}}
                updatable={{type: 'inline', url: 'users', msg: "Utilisateur modifié avec succès"}}
                creatable={{type: 'newWindow', url: '/users/new'}}
                skip={0}
                limit={500}
                columns={columns}
                onRowClick={(event, rowData) => navigate(`/users/${rowData?.id}`)}
                options={{grouping: true, pageSizeOptions: [100, 200, 500], pageSize: 100, filtering: true}}
                rebuildFunc={(args: AdminUserOut[]) => args.filter((item) => item.id !== 1)}
            />
        </FetchContextProvider>
    )
}
